import React from 'react';
import { Redirect } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import { Dropdown, DropdownButton, Button, Modal } from 'react-bootstrap';
// import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';

import { Locale, Lang } from '../Localization/CustomLocalization.js';
import LocalizationSwitcher from '../Localization/LocalizationSwitcher';

// import Background froms '../Screens/Quiz/images/Background.jpg';

import { NationalState, SchoolList, District } from '../components/NationalQuizEventSignUpSettings.js';
// import AutoComplete_v1 from '../components/AutoComplete_v1.js';

import { GlobalSetting } from '../components/GlobalSetting';
import { Delay, DelayUntil, CheckStringEmpty, CheckObjectNullValue, CheckNullValue, GradeOptions, CheckBoolean, CheckObjectBoolean } from '../components/GlobalFunctions';      //2023.09.11

export default class SignUpScreenV2 extends React.Component {

    constructor(props) {
        super(props);

        this.phoneNumberRef = React.createRef();
    }

    state = {
        redirect: false,
        redirectLink: '',

        isLoading: false,   //2023.10.30

        signupEmail: '',
        signupPassword: '',
        signupStudentName: '',
        signupStudentCenter: '',
        signupGradeSelection: '',

        //2020.11.18
        signupEmail_Confirm: '',
        signupPassword_Confirm: '',

        //2020.11.21
        signupContactNumber: '',

        //2020.11.24
        signup_NationalState: '',
        signup_SchoolName: '',
        signup_AgreePolicyTnc: false,

        //2020.11.25
        signup_GuardianName: '',
        toggleShowHide_GuardianNameField: false,

        //2020.12.03
        signup_CenterStudent: 'Not Specify',

        //2020.12.14
        signup_StudentGroup: 'Not Specify',
        signup_StudentGroupId: '',  //2021.07.03
        signup_StudentGroupLabel: '',   //2022.05.17

        //2021.07.30
        signup_Gender: '',
        signup_Race: '',
        // signup_Religion: '',

        //2021.08.26
        signup_DialingCode: '+60',
        signup_PhoneNumber: '',

        //2021.10.12
        signup_DistrictArea: '',
        filteredByState_DistrictAreaList: [],
        showSelectDistrictAreaOption: false,

        //2023.06.14
        signup_Classroom: '',

        //2022.01.10
        NewUserCredential: null,

        errorMessage: '',
        showModal: false,
        submitInProgress: false,

        isRealtimeProfileExists: false,
        isFireStoreProfileExists: false,

        // //2020.11.26
        // schoolListArray: [],
        // showSelectOption: false,

        //2020.11.27
        schoolListArray: [],
        nationalStateListArray: [],
        showSelectSchoolListOption: false,
        showSelectNationalStateOption: false,
        // isFromParentApp: false,

        //2020.12.03
        gradeOptions: [],

        //2021.03.19
        toggleUi_LoginOrRegister: false,
        isExistingUser: false,
        loginEmail: '',
        loginPassword: '',
        toggleLoginNotice: false,
        saveLoginCredential: false,

        //2021.07.30
        GenderOptions: [],
        RaceOptions: [],

        // //2021.08.27
        // debugText: '',

        //2022.01.14
        regCurrMomentUtc: moment(),
    }

    componentWillUnmount = () => {
        //2021.02.01
        localStorage.removeItem('EnterFromLogin');
        // //2021.08.25
        // localStorage.removeItem('SelectedEventCode');
        // if (this.phoneNumberRef.current !== null)
        //     this.phoneNumberRef.current.removeEventListener('keydown', this.checkNumberFieldInput, false);
    }

    //new added 2020.11.20
    componentDidMount = async () => {

        //2022.06.16
        window.scrollTo(0, 0);
        this.setState({
            regCurrMomentUtc: null,
        });

        //#region old codes
        // this.props.SetAlertConfirm('Notice',
        //     this.GetAutoRegSuccessMessage(),
        //     this.props.Feedback.Confirm, false, false, true, false, false);

        // this.props.SetAlertConfirm('Notice',
        //     this.GetAutoRegSuccessMessage_NewFirebaseUser_HasParentUser(),
        //     this.props.Feedback.Confirm, false, false, true, false, false);

        // this.props.SetAlertConfirm('Notice',
        //     this.GetAutoRegSuccessMessage_HasFirebaseUser_NewParentUser(),
        //     this.props.Feedback.Confirm, false, false, true, false, false);

        // this.props.SetAlertConfirm('Notice',
        //     this.GetAutoRegSuccessMessage_HasFirebaseUser_HasParentUser(),
        //     this.props.Feedback.Confirm, false, false, true, false, false);



        // this.props.SetAlertConfirm('Notice',
        //     this.GetAutoRegSuccessMessage_ExstingFirebaseUser(),
        //     this.props.Feedback.Confirm, false, false, true, false, false);

        // this.SetAlert(Locale("invalid-signup", this.props.Locale),
        //     '(API) ' + Locale("unable-to-create-user", this.props.Locale)
        //     + '<br /><br /><span class="blink-fast" style="font-weight:bold;color:red;">(Error) Phone Number Wrong Format</span>'
        //     + '<br /><br />' + Locale("auto-reg-try-again-message", this.props.Locale));





        // if (this.props.MobileSignUpPageReturnBypass) {
        //     this.props.SetMobileSignUpPageReturnBypass(false);
        //     this.GotoLoginPage();
        //     return null;
        // }

        // if (isMobile) {
        //     // alert('SignUp : ' + isMobile);
        //     let check = localStorage.getItem('MobileSignUpPageReturnBypass');
        //     // if (check !== '') {
        //     //     // localStorage.setItem('MobileSignUpPageReturnBypass', 'done');
        //     //     if (this.props.OrganizerIdentity !== '') {
        //     //         window.localtion.href = 'https://livequiz.ikeyedutech.com.my/' + this.props.OrganizerIdentity;
        //     //     }
        //     //     else {
        //     //         localStorage.setItem('MobileSignUpPageReturnBypass', '');
        //     //         this.setState({
        //     //             redirect: true,
        //     //             redirectLink: '/',
        //     //         });
        //     //     }
        //     // }
        //     // if (check !== '') {
        //     //     window.localtion.href = 'https://livequiz.ikeyedutech.com.my/' + this.props.OrganizerIdentity;
        //     // }
        //     // else {
        //     if (check === '') {
        //         localStorage.setItem('MobileSignUpPageReturnBypass', '');
        //         this.setState({
        //             redirect: true,
        //             redirectLink: '/',
        //         });
        //     }
        // }
        //#endregion

        //2021.08.25
        // await DelayUntil(() => this.props.Events.length > 0);
        await DelayUntil(() => this.props.IsActiveEventListLoaded === true);
        // console.log(this.props.Events.length > 0);

        this.props.SetLoading('', 'loading...', false);
        this.setState({
            isLoading: true,
        });

        //2021.08.23
        this.props.setLoginState({ Success: false, ErrorCode: '', Message: '' });

        //2021.08.19
        this.props.SetScreen(this.props.Screen.SignUpScreen);

        //2021.02.01
        let enterFromLogin = localStorage.getItem('EnterFromLogin');
        if (enterFromLogin === null)
            this.GotoLoginPage();
        else {
            localStorage.removeItem('EnterFromLogin');

            // //2021.08.25
            // let selectedEventCode = localStorage.getItem('SelectedEventCode');
            // if (selectedEventCode !== null) {

            //     console.log(selectedEventCode);
            //     // console.log(JSON.stringify(this.props.Events));
            //     console.log(this.props.isEventRegistration);

            //     // this.props.SetIsEvent(true, selectedEventCode);
            //     // await Delay(3000);
            //     // console.log(this.props.isEventRegistration);

            //     localStorage.removeItem('SelectedEventCode');
            // }
        }
        setTimeout(() => {
            // alert(this.props.email);
            if (String(this.props.email) !== '') {

                this.setState({
                    signupEmail: String(this.props.email),
                }, () => {
                    // alert(this.state.signupEmail);

                    // alert(NationalState);
                });
            }
        }, 500);

        // alert(this.props.isEventRegistration + "\n" + this.props.eventName + "\n" + this.props.centerName);

        // alert(this.props.isFromParentApp);
        // this.setState({
        //     isFromParentApp: this.props.isFromParentApp,
        // });

        // this.SchoolListSettingToArray();

        //2020.11.27
        await this.SettingListToArray();
        await DelayUntil(() => this.state.schoolListArray.length > 0 && this.state.nationalStateListArray.length > 0);

        //2021.03.19
        this.Set_FirstLoad_UiData();

        //2020.12.03
        setTimeout(() => {
            // this.PopulateGradeOptions();
            // this.PopulateOtherOptions();
            this.ResetOptionsLabel();   //2023.10.31
        }, 1000);

        this.setState({
            isLoading: false,
        });
        this.props.CloseAlert();
    }

    // Delay = ms => new Promise(res => setTimeout(res, ms));

    // DelayUntil = (conditionFunction) => {
    //     const poll = resolve => {
    //         if (conditionFunction()) resolve();
    //         else setTimeout(_ => poll(resolve), 500);
    //     }
    //     return new Promise(poll);
    // }

    //2021.07.30
    GetOptionsLabel = (options, value) => {
        let label = '';
        if (options.length > 0 && value !== '') {
            let index = options.findIndex(x => x.value === value);
            if (index > -1)
                label = options[index].label;
            // console.log('index : ' + index + '\nlabel : ' + label + '\nvalue : ' + value + '\n' + JSON.stringify(options[index]));
        }
        return label;
    }

    //2021.03.19 === Login Or Register Ui === start.
    Set_FirstLoad_UiData = () => {
        let saveLoginSrc = localStorage.getItem('saveLoginCredential');
        let saveLogin = saveLoginSrc !== null ?
            (String(saveLoginSrc).toLowerCase() === 'true' ? true : false)
            : true;

        this.setState({
            loginEmail: saveLogin ? localStorage.getItem('email') : '',
            loginPassword: saveLogin ? localStorage.getItem('password') : '',
            saveLoginCredential: saveLogin,
        });
    }

    Select_LoginOrRegister = (option) => {
        this.setState({
            toggleUi_LoginOrRegister: true,
            isExistingUser: option,
        }, () => {
            // if (option === false)
            //     this.phoneNumberRef.current.addEventListener('keydown', this.checkNumberFieldInput, false);
            // // else
            // //     this.phoneNumberRef.current.removeEventListener('keypress', this.checkNumberFieldInput, false);
        });
    }

    //copied from LoginScreen
    handleSetLoginEmail = (event) => {
        let email = CheckStringEmpty(event.target.value);
        email = email.length > 0 ? email.replace(new RegExp(' ', 'g'), '') : '';
        email = email.length > 0 ? email.toLowerCase() : '';
        this.setState({
            loginEmail: email,
        });
    }

    //copied from LoginScreen
    handleSetLoginPassword = (event) => {
        this.setState({
            loginPassword: CheckStringEmpty(event.target.value),
        });
    }

    //copied from LoginScreen
    handleSetSavePasword = () => {
        this.setState({
            saveLoginCredential: !this.state.saveLoginCredential,
        }, () => {
            //2021.03.19
            this.SaveLoginDetailsInLocal();
        });
    }

    //copied from LoginScreen
    SaveLoginDetailsInLocal = () => {
        if (typeof (Storage) !== 'undefined') {
            // code for localStorage/sessionStorage.
            localStorage.setItem('saveLoginCredential', this.state.saveLoginCredential);
            if (this.state.saveLoginCredential) {
                if (CheckNullValue(this.state.loginEmail) !== null)
                    localStorage.setItem('email', this.state.loginEmail);
                if (CheckNullValue(this.state.loginPassword) !== null)
                    localStorage.setItem('password', this.state.loginPassword);
            }
            else {
                localStorage.setItem('email', '');
                localStorage.setItem('password', '');
            }
        } else {
            // Sorry! No Web Storage support..      
        }
    }

    //copied from LoginScreen, partially edited.
    handleLogin = async () => {
        //2020.11.30
        if (this.props.isInternetReachable === false) {
            this.props.ShowInternetNotAvailableAlert();
            return null;
        }

        let msg = "";
        let isValid = true;

        //Login inputs validation.
        let isEmailEmpty = CheckNullValue(this.state.loginEmail) === null;
        let isPasswordEmpty = CheckNullValue(this.state.loginPassword) === null;

        if (isEmailEmpty)
            msg += "Please do not leave your <b>Email</b> empty.<br /><br />";

        if (isPasswordEmpty)
            msg += "Please do not leave your <b>Password</b> empty.<br /><br />";

        let isLoginEmailValid = true;
        if (!isEmailEmpty) {
            let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            isLoginEmailValid = regexp.test(this.state.loginEmail);
            if (!isLoginEmailValid) {
                msg += "Please enter your <b>Email</b> with the correct format.<br />(E.g. user@company.com)";
            }
        }

        if (isEmailEmpty || isPasswordEmpty || !isLoginEmailValid)
            isValid = false;

        //Proceed to login.
        if (isValid) {
            // //Save login info in local.
            // this.SaveLoginDetailsInLocal();

            // this.SetAlert(Locale("logging-in", this.props.Locale), Locale("please-wait", this.props.Locale));
            this.SetAlertWithProgressBarLocal(Locale("logging-in", this.props.Locale), Locale("please-wait", this.props.Locale), true);

            await this.props.login(this.state.loginEmail, this.state.loginPassword);

            //2021.03.19
            this.SaveLoginDetailsInLocal();
            this.GotoHome();
        }
        else {
            // this.SetAlert(Locale("invalid-login-info", this.props.Locale), msg);
            this.props.SetAlert(Locale("invalid-login-info", this.props.Locale), msg);
        }
    }

    GotoHome = () => {
        this.setState({
            redirectLink: '/home',
            redirect: true,
        });
    }
    //2021.03.19 === Login Or Register Ui === end.

    //2020.11.26
    SettingListToArray = async () => {
        let _schoolListArray = [];
        SchoolList.map((data, key) => {
            return _schoolListArray.push({ value: data, label: data });
        });

        let _nationalStateListArray = [];
        NationalState.map((data, key) => {
            return _nationalStateListArray.push({ value: data, label: data });
        });

        this.setState({
            schoolListArray: _schoolListArray,
            nationalStateListArray: _nationalStateListArray,
        });
        // navigator.clipboard.writeText(JSON.stringify(schoolListArray));
        // alert("SchoolList converted to JSON & copied to clipboard");
    }

    // SchoolListSettingToArray = () => {
    //     let _schoolListArray = [];
    //     SchoolList.map((data, key) => {
    //         return _schoolListArray.push({ value: data, label: data });
    //     });
    //     this.setState({
    //         schoolListArray: _schoolListArray,
    //     });
    //     // navigator.clipboard.writeText(JSON.stringify(schoolListArray));
    //     // alert("SchoolList converted to JSON & copied to clipboard");
    // }

    //=== Alert === starts.
    SetAlertLocal = (title, content) => {
        this.props.SetAlert(title, content);
        this.setState({
            submitInProgress: false,
        });
    }

    SetAlertWithProgressBarLocal = (title, content, showBar) => {
        this.props.SetAlertWithProgressBar(title, content, showBar);
        this.setState({
            submitInProgress: false,
        });
    }
    //=== Alert === ends.

    handleSetEmail = (event) => {
        let value = CheckStringEmpty(event.target.value);
        value = value !== null && value !== undefined ? String(value).toLowerCase().replace(new RegExp(' ', 'g'), '') : '';    //trim spaces.
        this.setState({
            // signupEmail: String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), ''),
            // signupEmail: event.target.value !== null ? String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), '') : '',
            signupEmail: value,
        });
    }

    //2020.11.18
    handleSetEmailConfirm = (event) => {
        let value = CheckStringEmpty(event.target.value);
        value = value !== null && value !== undefined ? String(value).toLowerCase().replace(new RegExp(' ', 'g'), '') : '';    //trim spaces.
        this.setState({
            // signupEmail_Confirm: String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), ''),
            // signupEmail_Confirm: event.target.value !== null ? String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), '') : '',
            signupEmail_Confirm: value,
        });
    }

    handleSetPassword = (event) => {
        this.setState({
            signupPassword: CheckStringEmpty(event.target.value),
        });
    }

    //2020.11.18
    handleSetPasswordConfirm = (event) => {
        this.setState({
            signupPassword_Confirm: CheckStringEmpty(event.target.value),
        });
    }

    handleSetName = (event) => {
        this.setState({
            signupStudentName: CheckStringEmpty(event.target.value),
        });
    }

    handleSetCenter = (event) => {
        let value = CheckStringEmpty(event.target.value);
        // value = value.replace(new RegExp(' ', 'g'), '');    //trim spaces.
        this.setState({
            signupStudentCenter: value,
        });
    }

    handleGradeSelect = (option) => {
        // alert(option.value);
        this.setState({
            signupGradeSelection: CheckStringEmpty(option.value),
        }, () => {

            //Estimated DOB.
            let _currYear = moment.utc().year();
            let _dob = (grade) => {
                grade = String(grade).toLowerCase();
                if (grade.includes('kd')) {
                    //2022.05.12
                    let digit = grade.split(' ')[1];
                    return String(_currYear - Number(digit)) + '-08-31 00:00:00';    //yyyy-mm-dd
                }
                else if (grade.includes('pre-school')) {
                    return String(_currYear - 4) + '-08-31 00:00:00';    //yyyy-mm-dd
                }
                else if (grade.includes('standard')) {
                    let digit = grade.split(' ')[1];
                    return String(_currYear - 6 - Number(digit)) + '-08-31 00:00:00';    //yyyy-mm-dd
                }
                else if (grade.includes('form')) {
                    let digit = grade.split(' ')[1];
                    return String(_currYear - 12 - Number(digit)) + '-08-31 00:00:00';    //yyyy-mm-dd
                }
                else {
                    // return '2020-08-31 00:00:00';   //other
                    return String(_currYear - 1) + '-08-31 00:00:00';
                }
            }
            if (this.props.isDevMode) {
                let _dobText = _dob(option.value);
                console.log(option.label + '\n' + option.value + '\n' + _dobText + '\n' + String(_currYear - moment(_dobText).year()) + '\nthis year = ' + _currYear);
            }

            //2020.12.15
            let _isBelow13 = this.CheckIfApplicantIsBelow13();
            this.setState({
                toggleShowHide_GuardianNameField: _isBelow13,
                disable_GuardianNameField: _isBelow13,
            });

            //2021.03.17
            let _isStandard = this.state.signupGradeSelection.toLowerCase().includes('standard');

            //2022.05.12
            let groups = this.getGroupOptions();
            // console.log(this.state.signupGradeSelection, this.state.signup_StudentGroup, this.state.signup_StudentGroupId);
            let _isKindergarden = this.state.signupGradeSelection.toLowerCase().includes('kd');
            let _studentGroup = 'Not Specify';
            let _studentGroupId = 0;
            let _studentGroupLabel = '';    //2022.05.17
            if (_isStandard) {
                for (let kk = 0; kk < groups.length; kk++) {
                    if (groups[kk].value === this.state.signupGradeSelection) {
                        _studentGroup = groups[kk].value;   //Standard 6
                        _studentGroupId = groups[kk].id;
                        _studentGroupLabel = groups[kk].label;      //2022.05.17
                        break;
                    }
                }
                // _studentGroup = this.state.signupGradeSelection;
            }
            else if (_isKindergarden) {
                // console.log(this.state.signupGradeSelection, this.state.signup_StudentGroup, this.state.signup_StudentGroupId);
                for (let kkk = 0; kkk < groups.length; kkk++) {
                    let temp = this.state.signupGradeSelection.toLowerCase().replace(' ', '');
                    if (String(groups[kkk].value).toLowerCase().includes(temp)) {
                        _studentGroup = groups[kkk].value;  //KD6
                        _studentGroupId = groups[kkk].id;
                        _studentGroupLabel = groups[kkk].label;     //2022.05.17
                        // console.log('found', groups[kkk]);
                        break;
                    }
                }
                // console.log(this.state.signupGradeSelection, _studentGroup, _studentGroupId);
            }
            else {
                _studentGroup = 'Not Specify';
            }
            this.setState({
                // signup_StudentGroup: _isStandard ? this.state.signupGradeSelection : 'Not Specify',
                signup_StudentGroup: _studentGroup,
                signup_StudentGroupId: _studentGroupId,
                signup_StudentGroupLabel: _studentGroupLabel,   //2022.05.17
            });
        });
    }

    //2020.12.15
    CheckIfApplicantIsBelow13 = () => {
        let _gradeSelection = this.state.signupGradeSelection.toLowerCase();
        let _isBelow13 = _gradeSelection.includes('pre') || _gradeSelection.includes('standard') || _gradeSelection.includes('kd');
        return _isBelow13;
    }

    //2021.08.26
    handleDialingCodeSelect = (option) => {
        this.setState({
            signup_DialingCode: option,
        }, () => {
            this.processContactNumber();
            if (this.props.isDevMode)
                console.log('Dialing Code = ' + this.state.signup_DialingCode);
        });
    }

    // //2021.08.26
    // handleSetPhoneNumber = (event) => {
    //     let value = String(event.target.value);
    //     this.setPhoneNumber(value);
    // }

    // //2021.08.26
    // checkNumberFieldInput = (event) => {

    //     // 0 for null values
    //     // 8 for backspace 
    //     // 48-57 for 0-9 numbers
    //     if (
    //         event.which != 8 && event.which != 0
    //         && (event.which < 48 || event.which > 57)
    //         && (event.which < 96 || event.which > 105)
    //     ) {
    //         // console.log(event.which);
    //         // this.phoneNumberRef.current.value = this.state.signup_PhoneNumber;
    //         event.preventDefault();
    //         //to prevent input symbol >>> "+","-",".","e"
    //     }

    //     setTimeout(() => {
    //         this.setState({
    //             debugText: 'event.which = ' + event.which
    //                 + '<br />event.key = ' + event.key
    //                 + '<br />current = ' + this.phoneNumberRef.current.value,
    //         });
    //     }, 300);
    // }

    //2021.08.26
    setPhoneNumber = (value = '') => {
        value = CheckStringEmpty(value);
        if (value.length > 15 - this.state.signup_DialingCode.length) {
            this.phoneNumberRef.current.value = value.slice(0, value.length - 1);
            return null;
        }
        // value = value.replace(/[^0-9]/g, '');
        if (value.charAt(0) === '0')
            value = value.slice(0, value.length - 1);

        // //check symbols.
        // // let arrayValue = Array.from(value);
        // // ['+', '-', '.', 'e', 'E', '±'].map((data, key) => {
        // //     let index = arrayValue.findIndex(x => x === data);
        // //     if (index > -1) {
        // //         value = value.replaceAll(data, '');
        // //         arrayValue = Array.from(value);
        // //     }
        // // });

        //only keeps 0~9.
        let arrayValue = Array.from(value);
        let newArrayValue = [];
        arrayValue.map((data, key) => {
            let index = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].findIndex(x => x === data);
            if (index > -1) {
                newArrayValue.push(data);
            }
            return null;
        });
        value = newArrayValue.join('');

        this.setState({
            signup_PhoneNumber: value,
        }, () => {
            this.phoneNumberRef.current.value = value;
            this.processContactNumber();
            if (this.props.isDevMode)
                console.log('Phone Number = ' + this.state.signup_PhoneNumber);
        });
    }

    //2021.08.26
    processContactNumber = () => {
        let value = CheckStringEmpty(this.state.signup_DialingCode) + CheckStringEmpty(this.state.signup_PhoneNumber);    //2021.08.26
        // value = value.replace(new RegExp('+', 'g'), '');
        // value = '+' + value;

        value = value.replace(new RegExp(' ', 'g'), '');    //trim spaces.
        value = value.replace(new RegExp('-', 'g'), '');    //trim dashes.

        if (value.length > 15) {
            let slicedPhoneNumber = value.slice(this.state.signup_DialingCode.length, 15);
            value = this.state.signup_DialingCode + slicedPhoneNumber;
            // console.log('sliced = ' + slicedPhoneNumber + '\nNew = ' + value + '\ncount = ' + value.length);
            this.setPhoneNumber(slicedPhoneNumber);
            return null;
        }

        this.setState({
            signupContactNumber: value,
        }, () => {
            if (this.props.isDevMode)
                console.log('Contact Number (final) = ' + this.state.signupContactNumber);
        });
    }


    // //2020.11.21
    // handleSetContactNumber = (event) => {
    //     let value = String(event.target.value);
    //     value = value.replace(new RegExp(' ', 'g'), '');    //trim spaces.
    //     value = value.replace(new RegExp('-', 'g'), '');    //trim dashes.

    //     // value = value.includes('+6') ? value : (value.startsWith('0', 0) ? '+6' + value : '+60' + value);    //add +6 at front.

    //     // if (!value.includes('+6'))
    //     //     if (value.startsWith('0', 0))
    //     //         value = '+6' + value;
    //     //     else
    //     //         value = '+60' + value;

    //     this.setState({
    //         signupContactNumber: value,
    //     }, () => {
    //         if (this.props.isDevMode)
    //             console.log('Contact Number (final) = ' + this.state.signupContactNumber);
    //     });
    // }

    //2020.11.24
    handleNationalStateSelect = (option) => {
        // alert(option);
        this.setState({
            signup_NationalState: CheckStringEmpty(option.value),
        }, () => {
            //2021.10.12
            this.FilterByState_DistrictAreaList();
        });
    }

    //2021.10.12
    FilterByState_DistrictAreaList = () => {
        this.setState({
            filteredByState_DistrictAreaList: [],
            signup_DistrictArea: '',
        }, () => {
            let findIndex = District.findIndex(x => x.State === this.state.signup_NationalState);
            if (findIndex > -1) {
                let districtArea = District[findIndex].Area;
                let _areas = [];
                districtArea.map((data, key) => {
                    return _areas.push({ value: data, label: data });
                });
                this.setState({
                    filteredByState_DistrictAreaList: _areas,
                });
            }
        });
    }

    //2021.10.12
    handleDistrictAreaSelect = (option) => {
        // alert(option);
        this.setState({
            signup_DistrictArea: CheckStringEmpty(option.value),
        });
    }

    //2023.06.14
    handleSetClassroom = (event) => {
        this.setState({
            signup_Classroom: CheckStringEmpty(event.target.value),
        });
    }

    //2020.11.24
    handleSetPolicyTnc = () => {
        this.setState({
            signup_AgreePolicyTnc: !this.state.signup_AgreePolicyTnc,
        }, () => {
            // setTimeout(() => {
            //     alert(this.state.signup_AgreePolicyTnc);
            // }, 1000);
        });
    }

    //2020.11.25
    handleSetSchoolName = (event) => {
        // alert(event.value);
        this.setState({
            // signup_SchoolName: event.target.value,
            signup_SchoolName: CheckStringEmpty(event.value),
        }, async () => {
            // alert(this.state.signup_SchoolName);
            await this.UpdateSchoolSelectHeight();      //2023.10.25

            if (this.props.isDevMode)
                console.log('handleSetSchoolName', this.state.signup_SchoolName);
        });
    }

    //2023.10.25
    UpdateSchoolSelectHeight = async () => {
        await Delay(500);
        const com = document.getElementById('r-select-school-register');
        if (com !== null) {
            let height = 0;
            const child_value_container = com.querySelector('.r-select__control .r-select__value-container');
            const child_placeholder = com.querySelector('.r-select__control .r-select__value-container .r-select__placeholder');
            // console.log(child_placeholder.innerHTML);            //value.
            // console.log(child_placeholder.clientHeight);         //component height.
            if (child_placeholder !== undefined && child_placeholder !== null && child_placeholder.classList.contains('r-select__placeholder'))
                height = Number(child_placeholder.clientHeight);
            if (height > 0)
                if (child_value_container !== undefined && child_value_container !== null && child_value_container.classList.contains('r-select__value-container'))
                    child_value_container.style.height = height + 'px';
        }
    }

    //2020.11.26
    handleSetGuardianName = (event) => {
        // alert(option);
        this.setState({
            signup_GuardianName: CheckStringEmpty(event.target.value),
        });
    }

    //2020.12.03
    handleSetIsCenterStudent = (option) => {
        // alert(option.value);
        this.setState({
            signup_CenterStudent: option.value,
        });
    }

    //2021.07.30
    handleSetGender = (option) => {
        // alert(option.value);
        this.setState({
            signup_Gender: CheckStringEmpty(option.value),
        });
    }

    //2021.07.30
    handleSetRace = (option) => {
        // alert(option.value);
        this.setState({
            signup_Race: CheckStringEmpty(option.value),
        });
    }

    // //2021.07.30
    // handleSetReligion = (option) => {
    //     // alert(option.value);
    //     this.setState({
    //         signup_Religion: option.value,
    //     });
    // }

    //2020.12.03
    getOptionText = (text) => {
        switch (String(text).toLowerCase()) {
            default: return Locale("not-specify-yes-no", this.props.Locale);
            case 'yes': return Locale("yes", this.props.Locale);
            case 'no': return Locale("no", this.props.Locale);
        }
    }

    //2020.12.14
    handleSetStudentGroup = (option) => {
        // alert(option.value);
        this.setState({
            signup_StudentGroup: option.value,
            signup_StudentGroupId: option.id,   //2021.07.03
        });
    }

    //2020.12.14
    getGroupAbacusText = (text) => {
        switch (String(text).toLowerCase()) {
            default: return Locale("not-specify-group", this.props.Locale);
            case 'group a': return Locale("group-a-abacus", this.props.Locale);
            case 'group b': return Locale("group-b-abacus", this.props.Locale);
            case 'group c': return Locale("group-c-abacus", this.props.Locale);
            case 'group d': return Locale("group-d-abacus", this.props.Locale);
        }
    }

    //2023.10.31
    ResetOptionsLabel = () => {
        this.PopulateGradeOptions();
        this.PopulateOtherOptions();
    }

    //2020.12.03
    PopulateGradeOptions = () => {

        //2023.11.03
        this.setState({
            gradeOptions: GradeOptions(this.props.Locale),
        });

        // let temp = [];

        // [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        //     temp.push({ value: 'Standard ' + stdIdx, label: this.GetStandard(stdIdx), id: stdIdx, })
        // ));

        // [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        //     temp.push({ value: 'Form ' + stdIdx, label: this.GetForm(stdIdx), id: stdIdx + 10, })
        // ));

        // temp.push({ value: 'Other', label: Locale("other", this.props.Locale), id: 17, });

        // temp.push({ value: 'Pre-School', label: Locale("pre-school", this.props.Locale), id: 31, });

        // //2022.05.12
        // [1, 2, 3].map((stdIdx) => (
        //     temp.push({ value: 'KD ' + (stdIdx + 3), label: Locale("kindergarden", this.props.Locale) + ' ' + (stdIdx + 3) + ' (' + (stdIdx + 3) + Locale("years-old", this.props.Locale) + ')', id: stdIdx + 27, })
        // ));

        // this.setState({
        //     gradeOptions: temp,
        // }, () => {
        //     // alert(JSON.stringify(this.state.gradeOptions));
        // });
    }

    //2021.07.30
    PopulateOtherOptions = () => {
        this.setState({
            GenderOptions: [
                { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", this.props.Locale) },
                { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", this.props.Locale) },
                { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", this.props.Locale) },
            ],
            RaceOptions: [
                { value: Locale("label-race-1", Lang.English), label: Locale("label-race-1", this.props.Locale) },
                { value: Locale("label-race-2", Lang.English), label: Locale("label-race-2", this.props.Locale) },
                { value: Locale("label-race-3", Lang.English), label: Locale("label-race-3", this.props.Locale) },
                { value: Locale("label-race-0", Lang.English), label: Locale("label-race-0", this.props.Locale) },
            ],
        });
    }

    //2020.12.03
    GetStandard = (idx) => {
        let text = "";
        if (this.props.Locale === Lang.Chinese)
            text = idx + " " + Locale("standard", this.props.Locale);
        else
            text = Locale("standard", this.props.Locale) + " " + idx;
        return text;
    }

    //2020.12.03
    GetForm = (idx) => {
        let text = "";
        if (this.props.Locale === Lang.Chinese) {
            if (idx > 0 && idx < 4)
                text = Locale("form-123", this.props.Locale) + " " + idx + " (" + Locale("form", this.props.Locale) + idx + ")";
            else if (idx > 3 && idx < 6)
                text = Locale("form-45", this.props.Locale) + " " + (idx - 3) + " (" + Locale("form", this.props.Locale) + idx + ")";
            else if (idx >= 6 && idx < 7)
                text = Locale("form-6", this.props.Locale) + " (" + Locale("form", this.props.Locale) + idx + ")";
        }
        else {
            text = Locale("form", this.props.Locale) + " " + idx;
        }
        return text;
    }

    //2020.12.03
    getGradeOptionText = (text = '') => {
        // alert(text);
        text = CheckStringEmpty(text).toLowerCase();
        let _texts = CheckStringEmpty(text).split(' ');
        // alert(_texts);
        let _key = _texts[0];
        // let _idx = _texts[1].toLowerCase();
        // let _idx = _key !== 'pre-school' && _key !== 'other' ? _texts[1].toLowerCase() : '0';
        let _idx = !text.includes('pre-school') || !text.includes('other') ? String(_texts[1]).toLowerCase() : '0';
        // console.log(text + '\n' + _idx);
        let result = '';
        switch (_key) {
            case 'pre-school': result = Locale("pre-school", this.props.Locale); break;
            case 'other': result = Locale("other", this.props.Locale); break;
            case 'standard': result = this.GetStandard(_idx); break;
            case 'form': result = this.GetForm(_idx); break;
            case 'kd': result = Locale("kindergarden", this.props.Locale) + ' ' + _idx + ' (' + _idx + Locale("years old", this.props.Locale) + ')'; break;
            default: result = Locale("grade", this.props.Locale); break;
        }
        // console.log('group = ' + result);
        return result;
    }

    // gradeText = () => {
    //     let temp = "";
    //     // [...Array(10)].map(() => temp += " ");
    //     temp += "Grade";
    //     // [...Array(10)].map(() => temp += " ");
    //     return temp;
    // }

    //2021.01.14
    getPlaceholder_GroupText = () => {
        return String(this.props.eventName).toLowerCase().includes('abacus') ?
            this.getGroupAbacusText(this.state.signup_StudentGroup)
            :
            this.state.signup_StudentGroup;
    }

    //2021.01.14    //options for event registration
    // getGroupOptions = () => {
    //     let _options = [];
    //     if (String(this.props.eventName).toLowerCase().includes('abacus')) {
    //         _options = [
    //             { value: 'Not Specify', label: this.getGroupAbacusText('Not Specify') },
    //             { value: 'Group A', label: this.getGroupAbacusText('Group A') },
    //             { value: 'Group B', label: this.getGroupAbacusText('Group B') },
    //             { value: 'Group C', label: this.getGroupAbacusText('Group C') },
    //             { value: 'Group D', label: this.getGroupAbacusText('Group D') },
    //         ];
    //     }
    //     else if (String(this.props.eventCode).toLowerCase().includes('colin')) {
    //         this.state.gradeOptions.map((data, key) => {
    //             if (String(data.value).includes('Form'))
    //                 _options.push({ value: data.value, label: data.value });
    //             return null;
    //         });
    //         //temp remove Form 6 & add Form 5 (Batch 2020).
    //         _options = _options.filter(x => !x.value.includes(6));
    //         _options.push({ value: "Form 5 (Batch 2020)", label: "Form 5 (Batch 2020)" });
    //     }
    //     else {
    //         // this.state.gradeOptions.map((data, key) => {
    //         //     return _options.push({ value: data.value, label: data.value });
    //         // });

    //         //2021.02.10
    //         let _special = String(this.props.eventModal.ExtraGradeSetting);
    //         // if (String(this.props.eventModal.EventCode).toLowerCase().includes('ptsmc'))
    //         // _special = 'Standard';
    //         // alert(_special);

    //         if (_special === 'primary-only')
    //             this.state.gradeOptions.map((data, key) => {
    //                 if (String(data.value).includes('Standard'))
    //                     _options.push({ value: data.value, label: data.value });
    //                 return null;
    //             });
    //         else if (_special === 'secondary-only')
    //             this.state.gradeOptions.map((data, key) => {
    //                 if (String(data.value).includes('Form'))
    //                     _options.push({ value: data.value, label: data.value });
    //                 return null;
    //             });
    //         else
    //             this.state.gradeOptions.map((data, key) => {
    //                 return _options.push({ value: data.value, label: data.value });
    //             });
    //     }
    //     return _options;
    // }

    //2021.02.10
    getGroupOptions = () => {
        let _options = [];
        if (this.props.eventModal !== null) {
            // this.props.eventModal.Groups.map((data, key) => {
            //     // return _options.push({ value: data, label: data });
            //     return _options.push({ value: data.Name, label: data.Name, id: data.Id, });
            // });

            //2021.08.25
            if (this.props.eventModal.QuizRooms !== null) {
                this.props.eventModal.QuizRooms.map((data, key) => {
                    // return _options.push({ value: data, label: data });
                    let _label = data.Group + (data.hasOwnProperty('Remark') ? (CheckObjectNullValue(data, 'Remark') !== null ? ' ' + data.Remark : '') : '');
                    return _options.push({ value: data.Group, label: _label, id: data.GroupId, });
                });
            }
        }
        return _options;
    }

    handleResetForm = () => {
        this.setState({
            signupEmail: '',
            signupPassword: '',
            signupStudentName: '',
            signupStudentCenter: '',
            signupGradeSelection: '',

            //2020.11.18
            signupEmail_Confirm: '',
            signupPassword_Confirm: '',

            //2020.12.03
            signupContactNumber: '',
            signup_NationalState: '',
            signup_SchoolName: '',
            signup_GuardianName: '',
            toggleShowHide_GuardianNameField: false,
            // disable_GuardianNameField: false,   //2020.12.15
            signup_AgreePolicyTnc: false,
            signup_CenterStudent: 'Not Specify',

            //2020.12.15
            signup_StudentGroup: 'Not Specify',
            disable_GuardianNameField: false,

            //2021.07.30
            signup_Gender: '',
            signup_Race: '',
            // signup_Religion: '',

            //2021.10.12
            signup_DistrictArea: '',

            //2023.06.14
            signup_Classroom: '',

            //2022.01.10
            NewUserCredential: null,
        });
    }

    handleSubmitForm = () => {
        //2020.11.30
        if (this.props.isInternetReachable === false) {
            this.props.ShowInternetNotAvailableAlert();
            return null;
        }

        this.setState({
            errorMessage: '',
            submitInProgress: true,
            isRealtimeProfileExists: false,
            isFireStoreProfileExists: false,
        }, async () => {
            //Show loading/progression status modal.
            // this.SetAlert("User profile creation in progress...", "Please wait patiently.");
            // this.SetAlertWithProgressBarLocal("User account creation in progress...", "Please wait patiently.", true);
            this.SetAlertWithProgressBarLocal(Locale("user-creation-in-progress", this.props.Locale),
                Locale("wait-patiently", this.props.Locale), true);

            //Check for inputs validation.
            let isValid = this.SignUpInfoValidation();

            // console.log('validation completed.');
            // return null;

            //Check for existing account.
            // if (isValid)
            //     this.CheckEmailAvailability();
            // isValid = this.CheckEmailAvailability();

            //Create new user profile if email is fresh/new/not existence.
            if (isValid) {
                await this.CreateNewUserInFirebase();

                // this.props.SetAlert("Registration Disabled", "Registration is disabled until development are fully done.");
            }
        });
    }

    SignUpInfoValidation = () => {
        let isValid = true;

        const isEmailEmpty = CheckNullValue(this.state.signupEmail) === null;
        const isPasswordEmpty = CheckNullValue(this.state.signupPassword) === null;
        const isNameEmpty = CheckNullValue(this.state.signupStudentName) === null;
        // let isCenterEmpty = this.state.signupStudentCenter.length <= 0;
        const isGradeUnselected = CheckNullValue(this.state.signupGradeSelection) === null;

        //2020.11.18
        const isEmailConfirmEmpty = CheckNullValue(this.state.signupEmail_Confirm) === null;
        const isPasswordConfirmEmpty = CheckNullValue(this.state.signupPassword_Confirm) === null;

        //2020.11.21
        const isContactNumberEmpty = CheckNullValue(this.state.signupContactNumber) === null;

        //2021.08.26
        const isContactNumberTooShort = this.state.signupContactNumber.length <= 5;

        //2020.11.25
        const isNationalStateUnselected = CheckNullValue(this.state.signup_NationalState) === null;
        const isPolicyTncUnchecked = this.state.signup_AgreePolicyTnc === false;

        // //2021.01.05 - school name not compulsory
        // const isSchoolNameEmpty = this.state.signup_SchoolName.length <= 0;

        //2020.11.26
        const isGuardianChecked = this.state.toggleShowHide_GuardianNameField;
        const isGuardianNameEmpty = isGuardianChecked ? this.state.signup_GuardianName.trim().length <= 0 : false;

        //2020.12.03    //2020.12.04 edited.
        const isCenterStudentNotSelected = this.props.isEventRegistration ? this.state.signup_CenterStudent === 'Not Specify' : false;

        //2020.12.14
        const isStudentGroupNotSelected = this.props.isEventRegistration ? this.state.signup_StudentGroup === 'Not Specify' : false;

        //2021.08.23
        const isPasswordTooShort = this.state.signupPassword.length < 6;
        const isPasswordConfirmTooShort = this.state.signupPassword_Confirm.length < 6;

        //2021.10.12
        const isDistrictAreaNotSelected = CheckNullValue(this.state.signup_DistrictArea) === null;

        //2023.10.30 - open back for checking & assign default value.
        //2022.10.07
        const isGenderNotSelected = CheckNullValue(this.state.signup_Gender) === null;  //_genderId(this.state.signup_Gender) === 30;
        const isRaceNotSelected = CheckNullValue(this.state.signup_Race) === null;
        const isSchoolNameEmpty = CheckNullValue(this.state.signup_SchoolName) === null;      //open back checking.
        if (isGenderNotSelected) {
            this.setState({ signup_Gender: 'Other' });
        }
        if (isRaceNotSelected) {
            this.setState({ signup_Race: 'Other' });
        }
        if (isSchoolNameEmpty) {
            this.setState({ signup_SchoolName: 'OTHER' });
        }


        //========//

        let msg = "";

        if (isEmailEmpty) {
            // msg += "Please do not leave your <b>Email</b> empty.<br />";
            msg += Locale("email-not-empty", this.props.Locale) + "<br /><br />";
        }

        //2020.11.18
        if (isEmailConfirmEmpty) {
            // msg += "Please do not leave your <b>Email (Confirm)</b> empty.<br />";
            msg += Locale("email-confirm-not-empty", this.props.Locale) + "<br /><br />";
        }

        if (isPasswordEmpty) {
            // msg += "Please do not leave your <b>Password</b> empty.<br />";
            msg += Locale("password-not-empty", this.props.Locale) + "<br /><br />";
        }

        //2020.11.18
        if (isPasswordConfirmEmpty) {
            // msg += "Please do not leave your <b>Password (Confirm)</b> empty.<br />";
            msg += Locale("password-confirm-not-empty", this.props.Locale) + "<br /><br />";
        }

        //2021.08.23
        if (isPasswordTooShort) {
            msg += Locale("password-too-short", this.props.Locale) + "<br /><br />";
        }
        if (isPasswordConfirmTooShort) {
            msg += Locale("password-confirm-too-short", this.props.Locale) + "<br /><br />";
        }

        if (isNameEmpty) {
            // msg += "Please do not leave your <b>Name</b> empty.<br />";
            msg += Locale("name-not-empty", this.props.Locale) + "<br /><br />";
        }

        //2020.11.21
        if (isContactNumberEmpty) {
            // msg += "Please do not leave your <b>Contact Number</b> empty.<br />";
            msg += Locale("contact-number-not-empty", this.props.Locale) + "<br /><br />";
        }

        //2021.08.26
        if (isContactNumberTooShort) {
            msg += Locale("contact-number-too-short", this.props.Locale) + "<br /><br />";
        }

        // if (isCenterEmpty) {
        //     // msg += "Please do not leave your <b>Center</b> empty.<br />";
        //     msg += Locale("center-not-empty", this.props.Locale) + "<br /><br />";
        // }

        if (isGradeUnselected) {
            // msg += "Please do not leave your <b>Grade</b> unselected.<br />";
            msg += Locale("grade-not-selected", this.props.Locale) + "<br /><br />";
        }

        //2020.11.25
        if (isNationalStateUnselected) {
            // msg += "Please do not leave your <b>National State</b> unselected.<br />";
            msg += Locale("national-state-not-selected", this.props.Locale) + "<br /><br />";
        }

        // //2021.01.05 - school name not compulsory
        // //2020.11.25
        // if (isSchoolNameEmpty) {
        //     msg += "Please do not leave your <b>School Name</b> empty.<br />";
        //     msg += Locale("school-name-not-empty", this.props.Locale) + "<br /><br />";
        // }

        //2020.11.26
        if (isGuardianNameEmpty) {
            // msg += "Upon checked on the <b><u>12 years old and below</u></b> option, please do not leave your <b>Guardian Name</b> empty.<br />";
            msg += Locale("guardian-name-not-empty", this.props.Locale) + "<br /><br />";
        }

        //2020.11.25
        if (isPolicyTncUnchecked) {
            // msg += "You must agree on the <b><u>Privacy Policy</u> and <u>Terms & Conditions</u></b> in order to proceed your registration.<br />";
            msg += Locale("policy-tnc-not-checked", this.props.Locale) + "<br /><br />";
        }

        //2020.12.03    //2020.12.04 edited.
        if (isCenterStudentNotSelected) {
            // msg += "Please do not leave your <b>Are you a SIM Student ?</b> unselected.<br />";
            msg += Locale("center-student-not-selected-1", this.props.Locale)
                + this.props.centerName
                + Locale("center-student-not-selected-2", this.props.Locale)
                + "<br /><br />";
        }

        //2020.12.14
        if (isStudentGroupNotSelected) {
            // msg += "Please select your group.";
            msg += Locale("student-group-not-selected", this.props.Locale)
                + "<br /><br />";
        }

        let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let isLoginEmailValid = true;
        if (!isEmailEmpty) {
            isLoginEmailValid = regexEmail.test(this.state.signupEmail);
            if (!isLoginEmailValid) {
                // msg += (msg.length > 0 ? "<br />" : "") + "Please enter your <b>Email</b> with the correct format.<br />(E.g. user@company.com)";
                msg += Locale("email-bad-format", this.props.Locale) + "<br /><br />";
            }
        }

        //2020.11.18
        let isLoginEmailConfirmValid = true;
        if (!isEmailConfirmEmpty) {
            isLoginEmailConfirmValid = regexEmail.test(this.state.signupEmail_Confirm);
            if (!isLoginEmailConfirmValid) {
                // msg += (msg.length > 0 ? "<br />" : "") + "Please enter your <b>Email (Confirm)</b> with the correct format.<br />(E.g. user@company.com)";
                msg += Locale("email-confirm-bad-format", this.props.Locale) + "<br /><br />";
            }
        }

        //2020.11.21
        // let regexContactNumber = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/;
        // let regexContactNumber = /^(\+?6?01)[0-46-9][0-9]{7,8}$/;
        let isContactNumberFormatValid = true;
        // if (!isContactNumberEmpty) {
        //     isContactNumberFormatInvalid = regexContactNumber.test(this.state.signupContactNumber);
        //     if (!isContactNumberFormatValid) {
        //         msg += Locale("contact-number-bad-format", this.props.Locale) + "<br /><br />";
        //     }
        // }
        //------disable 1st = not checking, coz may have users using phone number other than Malaysia mobile standards.

        //2020.11.18
        let isEmailNotMatch = false;
        if (!isEmailEmpty && !isEmailConfirmEmpty) {
            if (this.state.signupEmail !== this.state.signupEmail_Confirm) {
                isEmailNotMatch = true;
                msg += Locale("email-not-matched", this.props.Locale) + "<br /><br />";
            }
        }

        //2020.11.18
        let isPasswordNotMatch = false;
        if (!isPasswordEmpty && !isPasswordConfirmEmpty) {
            if (this.state.signupPassword !== this.state.signupPassword_Confirm) {
                isPasswordNotMatch = true;
                msg += Locale("password-not-matched", this.props.Locale) + "<br /><br />";
            }
        }

        //2021.10.12
        if (isDistrictAreaNotSelected) {
            // msg += "Please do not leave your <b>District</b> unselected.<br />";
            msg += Locale("district-area-not-selected", this.props.Locale) + "<br /><br />";
        }


        if (
            isEmailEmpty || isPasswordEmpty || isNameEmpty
            // || isCenterEmpty 
            || isGradeUnselected || !isLoginEmailValid
            || isEmailConfirmEmpty || isPasswordConfirmEmpty || !isLoginEmailConfirmValid
            || isEmailNotMatch || isPasswordNotMatch
            || isPasswordTooShort || isPasswordConfirmTooShort  //2023.01.04
            || isContactNumberEmpty || !isContactNumberFormatValid   //2020.11.18
            || isContactNumberTooShort  //2021.08.26
            // || isNationalStateUnselected || isSchoolNameEmpty || isPolicyTncUnchecked   //2020.11.25
            || isNationalStateUnselected || isPolicyTncUnchecked   //2021.01.05 - school name not compulsory
            || isGuardianNameEmpty  //2020.11.26
            || isCenterStudentNotSelected  //2020.12.03
            || isStudentGroupNotSelected    //2020.12.14
            || isDistrictAreaNotSelected    //2021.10.12
        ) {
            isValid = false;
            this.SetAlertWithProgressBarLocal(Locale("invalid-info", this.props.Locale), msg, false);

            // alert(
            //     '\n' + 'isEmailEmpty = ' + isEmailEmpty
            //     + '\n' + 'isPasswordEmpty = ' + isPasswordEmpty
            //     + '\n' + 'isNameEmpty = ' + isNameEmpty
            //     // + isCenterEmpty 
            //     + '\n' + 'isGradeUnselected = ' + isGradeUnselected
            //     + '\n' + '!isLoginEmailValid = ' + !isLoginEmailValid
            //     + '\n' + 'isEmailConfirmEmpty = ' + isEmailConfirmEmpty
            //     + '\n' + 'isPasswordConfirmEmpty = ' + isPasswordConfirmEmpty
            //     + '\n' + '!isLoginEmailConfirmValid = ' + !isLoginEmailConfirmValid
            //     + '\n' + 'isEmailNotMatch = ' + isEmailNotMatch
            //     + '\n' + 'isPasswordNotMatch = ' + isPasswordNotMatch
            //     + '\n' + 'isContactNumberEmpty = ' + isContactNumberEmpty
            //     + '\n' + '!isContactNumberFormatValid = ' + !isContactNumberFormatValid
            //     + '\n' + 'isNationalStateUnselected = ' + isNationalStateUnselected
            //     + '\n' + 'isSchoolNameEmpty = ' + isSchoolNameEmpty
            //     + '\n' + 'isPolicyTncUnchecked = ' + isPolicyTncUnchecked
            //     + '\n' + 'isGuardianNameEmpty = ' + isGuardianNameEmpty
            // );
        }
        // if (!isValid)
        //     alert(msg);

        // if (!isValid) {
        //     // this.SetAlertWithProgressBarLocal("Invalid Information", msg, false);
        //     this.SetAlertWithProgressBarLocal(Locale("invalid-info", this.props.Locale), msg, false);
        // }

        return isValid;
    }

    // getMessage = () => {
    //     return { __html: this.state.errorMessage };
    // }

    // SetSteps(step) {
    //     this.setState({ submitStep: step }, () => {
    //         //Show loading/progression status modal.
    //         this.SetAlert("User profile creation in progress...(" + this.state.submitStep + "/" + this.state.totalStep + ")", "Please wait patiently.");
    //     });
    // }

    // async CheckEmailAvailability() {
    //     // let isValid = true;

    //     // //Check Email on Realtime Database.
    //     // this.setState({ submitStep: 2 });
    //     // isValid = await this.CheckEmailOnRealtimeDatabase();

    //     // //Check Email on FireStore.
    //     // this.setState({ submitStep: 3 });
    //     // isValid = await this.CheckEmailOnFirestore();

    //     // //return result. found = invalid.
    //     // return isValid;

    //     //Check Email on Realtime Database.
    //     await this.CheckEmailOnRealtimeDatabase();

    //     //Check Email on FireStore.
    //     await this.CheckEmailOnFirestore();
    // }

    // async CheckEmailOnRealtimeDatabase() {
    //     // let isEmailNotExist = false;

    //     // await this.props.dbCommon.ref('Common/' + this.props.user.uid + "/_7Email").once('value', snapshot => {
    //     //     isEmailNotExist = !snapshot.exists;
    //     // });

    //     await this.props.dbCommon.ref("Common")
    //         .orderByChild("_7Email").equalTo(this.state.signupEmail)
    //         .once('value', snapshot => {
    //             // isEmailNotExist = !snapshot.exists;
    //             this.setState({ isRealtimeProfileExists: snapshot.exists });
    //         });

    //     // return isEmailNotExist;
    // }

    // async CheckEmailOnFirestore() {
    //     // let isEmailNotExist = false;

    //     await this.props.firestore.collection("User")
    //         .where('Email', '==', this.state.signupEmail)
    //         .get()
    //         .then(querySnapshot => {
    //             // isEmailNotExist = !querySnapshot.exists;
    //             this.setState({ isFireStoreProfileExists: querySnapshot.exists });
    //         });

    //     // return isEmailNotExist;
    // }

    //Find Gender.
    _genderId = (gender) => {
        switch (gender) {
            case Locale("label-gender-male", Lang.English): return Number(10);
            case Locale("label-gender-female", Lang.English): return Number(20);
            default: return Number(30);
        }
    }

    //2021.08.17
    NewParentRegistrationWorkflowViaLiveQuizPortal = async () => {
        let _OperationHalted = false;
        let _isApiWorkflowSuccess = false;
        let _errorMessage = '';
        let apiResponse = null;

        // //Find Gender.
        // let _genderId = (gender) => {
        //     switch (gender) {
        //         case Locale("label-gender-male", Lang.English): return Number(10);
        //         case Locale("label-gender-female", Lang.English): return Number(20);
        //         default: return Number(30);
        //     }
        // }

        //Estimated DOB.
        let _dob = (grade) => {
            grade = String(grade).toLowerCase();
            if (grade.includes('pre-school')) {
                return String(moment().utc().year() - 4) + '-08-31 00:00:00';    //yyyy-mm-dd
            }
            else if (grade.includes('standard')) {
                let digit = grade.split(' ')[1];
                return String(moment().utc().year() - 6 - Number(digit)) + '-08-31 00:00:00';    //yyyy-mm-dd
            }
            else if (grade.includes('form')) {
                let digit = grade.split(' ')[1];
                return String(moment().utc().year() - 12 - Number(digit)) + '-08-31 00:00:00';    //yyyy-mm-dd
            }
            else {
                // return '2020-08-31 00:00:00';   //other
                return String(moment().utc().year() - 1) + '-08-31 00:00:00';
            }
        }

        const genderId = this._genderId(this.state.signup_Gender);  //2022.10.08
        let registerModal = {
            ParentModel: {
                Name: this.state.signup_GuardianName.trim(),
                IdentityCardNumber: '000000-00-0000',
                GenderId: genderId,
                RelationshipId: 7,  //7 = not specify.
                Email: this.state.signupEmail.trim(),
                Password: this.state.signupPassword,
                DialingCode: '+60',
                ContactNumber: this.state.signupContactNumber.trim(),
                Address1: '-',
                Address2: '-',
                City: '-',
                PostalCode: '00000',
                StateProvince: '-',
                StateProvinceCode: '0',
                Country: 'Malaysia',
                CountryCode: '129',
                AgreeTnC: this.state.signup_AgreePolicyTnc,
            },
            ChildModel: {
                Name: this.state.signupStudentName.trim(),
                GenderId: genderId,
                IdentificationNumber: '',
                DateOfBirth: _dob(this.state.signupGradeSelection),
            }
        };

        let done = false;
        await fetch(GlobalSetting.ApiUrl + 'Api/Parent/Account/Registration/LiveQuizPortal/New',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'no-cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin',                 // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                         // manual, *follow, error
                // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(registerModal), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(async (data) => {
                // await Delay(500);
                if (this.props.isDevMode)
                    console.log('data = ' + JSON.stringify(data));
                data = JSON.parse(JSON.stringify(data));
                // data = { ...data };
                // console.log('data (returned) = ' + JSON.stringify(data));
                apiResponse = data;
                _isApiWorkflowSuccess = data.result.success;
                _errorMessage += data.result.message;
                // if (data.result.success)
                //     isApiWorkflowSuccess = true;
                // else
                //     errorMessage += data.result.message;
                // errorMessage += '<br /> | api workflow response. ' + data.result.message;
                done = true;
            })
            .catch(error => {
                // errorMessage += '<br /> | api workflow failed catch. ' + error.message;  // + '<br />' + loginModal;
                done = true;
                _errorMessage += error.message;
                // console.log('error (returned) = ' + error.message);
            });
        // console.log('isApiWorkflowSuccess = ' + isApiWorkflowSuccess);
        // if (!isApiWorkflowSuccess) {
        //     this.SetAlert('Error', errorMessage + '<br /><br />' + JSON.stringify(apiResponse));
        // }
        await DelayUntil(() => done === true);

        //2021.08.18
        let _parentId = 0;
        let _parentUserId = 0;
        let _isParentPreExisted = false;
        if (apiResponse !== null) {
            let parentInfo = JSON.parse(apiResponse.result.data);
            if (parentInfo !== null && parentInfo !== undefined) {
                _parentId = parentInfo.ParentId;
                _parentUserId = parentInfo.ParentUserId;
                if (parentInfo.hasOwnProperty('IsParentPreExisted'))
                    _isParentPreExisted = parentInfo.IsParentPreExisted;
                if (parentInfo.hasOwnProperty('OperationHalted'))
                    _OperationHalted = parentInfo.OperationHalted;  //2021.08.21
                // if (parentId > 0 && parentUserId > 0)
                //     isParentPreExisted = true;
                if (this.props.isDevMode)
                    console.log('\nParentId = ' + _parentId + '\nParentUserId = ' + _parentUserId);
            }
        }

        return {
            Success: _isApiWorkflowSuccess,
            ParentId: _parentId,
            ParentUserId: _parentUserId,
            IsParentPreExisted: _isParentPreExisted,
            ErrorMessage: _errorMessage,
            OperationHalted: _OperationHalted,
        };
    }

    //2021.08.19
    TestSendEmail = (name, email, password) => {
        this.setState({
            signup_GuardianName: name,
            signupEmail: email,
            signupPassword: password,
        }, async () => {
            await this.SendWelcomeEmailToNewParentRegistration();
            this.setState({
                signup_GuardianName: '',
                signupEmail: '',
                signupPassword: '',
            });
        });
    }

    OpenNewWindow = (link) => {
        return "window.open('" + link + "')";
    }

    //2023.05.03
    GetCommonRegSuccessMessage = () => {
        // let message = Locale("auto-reg-success-message", this.props.Locale);
        let message = "<span style='font-size:18px;font-weight:bold;'>Account Successfully Created!</span><br />";
        // message += "You can also use this email and password to login iKEY App to access the completed live quiz results and certificates.";
        message += "This email has been sucessfully registered. Please proceed to login page to login.<br /><br />";
        message += "You can access the completed live quiz results and certificates via <b>IKEY App</b>.";
        message += "<br /><br /><span style='font-size:16px;font-weight:bold;'><table cellpadding='5' cellspacing='0' width='100%' border='0'><tbody>";
        message += "<tr><td colspan='3' align='center'>Download the iKEY App now:</td></tr>";
        message += "<tr>";
        message += "<td align='right'><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://apps.apple.com/us/app/ikey-parent/id1476014901') + ">";
        message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/app_store_logo.png' alt='app_store_logo' width='100%' /></button></td>";
        message += "<td>&nbsp;&nbsp;</td>";
        message += "<td><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full') + ">";
        message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/google_play_logo.png' alt='google_play_logo' width='100%' /></button></td>";
        message += "</tr>";
        // message += "<tr><td colspan='3' align='center'>to download.</td></tr>";
        message += "</tbody></table></span>";
        return message;
    }

    //2021.08.19
    GetAutoRegSuccessMessage = () => {
        // let message = Locale("auto-reg-success-message", this.props.Locale);
        let message = "<span style='font-size:18px;font-weight:bold;'>Account Successfully Created!</span><br />";
        message += "You can also use this email and password to login iKEY App to access the completed live quiz results and certificates.";
        message += "<br /><br /><span style='font-size:16px;font-weight:bold;'><table cellpadding='5' cellspacing='0' width='100%' border='0'><tbody>";
        message += "<tr><td colspan='3' align='center'>Download the iKEY App now:</td></tr>";
        message += "<tr>";
        message += "<td align='right'><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://apps.apple.com/us/app/ikey-parent/id1476014901') + ">";
        message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/app_store_logo.png' alt='app_store_logo' width='100%' /></button></td>";
        message += "<td>&nbsp;&nbsp;</td>";
        message += "<td><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full') + ">";
        message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/google_play_logo.png' alt='google_play_logo' width='100%' /></button></td>";
        message += "</tr>";
        // message += "<tr><td colspan='3' align='center'>to download.</td></tr>";
        message += "</tbody></table></span>";
        return message;
    }

    //2021.08.21
    GetAutoRegSuccessMessage_HasFirebaseUser_NewParentUser = () => {
        // let message = Locale("auto-reg-success-message", this.props.Locale);
        let message = "This email has been previously registered via this Live Quiz Portal, please login using this email and the <b><u>previously registered</u></b> password in order to access the live quiz services.<br /><br />";
        // message += loginState.Success ? 'Profile has been updated.<br /><br />' : '';
        message += "You may change your password via the <b>Forgot?</b> link found at the Login page above the password field.<br /><br />";
        message += "Meanwhile,<br />";
        // message += "<span style='font-weight:bold;'>An Account was Successfully Created for iKEY App.</span><br />";
        // message += "You can use this email and password to login iKEY App.";    //<br />To change the password for iKEY App, please change via iKEY App.";
        message += "<span style='font-size:18px;font-weight:bold;'>Account Successfully Created!</span><br />";
        message += "You can also use this email and password to login iKEY App to access the completed live quiz results and certificates.";
        message += "<br /><br /><span style='font-size:16px;font-weight:bold;'><table cellpadding='5' cellspacing='0' width='100%' border='0'><tbody>";
        message += "<tr><td colspan='3' align='center'>Download the iKEY App now:</td></tr>";
        message += "<tr>";
        message += "<td align='right'><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://apps.apple.com/us/app/ikey-parent/id1476014901') + ">";
        message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/app_store_logo.png' alt='app_store_logo' width='100%' /></button></td>";
        message += "<td>&nbsp;&nbsp;</td>";
        message += "<td><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full') + ">";
        message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/google_play_logo.png' alt='google_play_logo' width='100%' /></button></td>";
        message += "</tr>";
        // message += "<tr><td colspan='3' align='center'>to download.</td></tr>";
        message += "</tbody></table></span>";
        return message;
    }

    //2021.08.23
    GetAutoRegSuccessMessage_NewFirebaseUser_HasParentUser = () => {
        // let message = Locale("auto-reg-success-message", this.props.Locale);
        // let message = "This email has been sucessfully registered.<br /><br />";
        let message = "This email has been sucessfully registered. Please proceed to login page to login.";
        // let message = loginState.Success ?
        //     "This email has been sucessfully registered."
        //     :
        //     loginState.ErrorCode === 'auth/wrong-password' ?
        //         "This email has been previously registered via this Live Quiz Portal, please login using this email and the <b><u>previously registered</u></b> password in order to access the live quiz services.<br /><br />"
        //         : "";
        // message += "Meanwhile,<br />";
        // message += "<span style='font-weight:bold;'>An Account was already exist for iKEY App.</span><br />";
        // message += "There is an account previously registered with this email for iKEY App. Please login iKEY App using <b><u>previously registered</u></b> password in order to access iKEY services.";
        // message += "<br /><br /><span style='font-size:16px;font-weight:bold;'><table cellpadding='5' cellspacing='0' width='100%' border='0'><tbody>";
        // message += "<tr><td colspan='3' align='center'>Download the iKEY App now:</td></tr>";
        // message += "<tr>";
        // message += "<td align='right'><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://apps.apple.com/us/app/ikey-parent/id1476014901') + ">";
        // message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/app_store_logo.png' alt='app_store_logo' width='100%' /></button></td>";
        // message += "<td>&nbsp;&nbsp;</td>";
        // message += "<td><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full') + ">";
        // message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/google_play_logo.png' alt='google_play_logo' width='100%' /></button></td>";
        // message += "</tr>";
        // message += "<tr><td colspan='3' align='center'>to download.</td></tr>";
        message += "</tbody></table></span>";
        return message;
    }

    //2021.08.23
    GetAutoRegSuccessMessage_HasFirebaseUser_HasParentUser = () => {
        // let message = Locale("auto-reg-success-message", this.props.Locale);
        let message = "This email has been previously registered via this Live Quiz Portal, ";
        // message += loginState.Success ? 'and profile has been updated, ' : '';
        message += "please login using this email and the <b><u>previously registered</u></b> password in order to access the live quiz services.<br /><br />";
        // message += "Meanwhile,<br />";
        // message += "<span style='font-weight:bold;'>An Account was already exist for iKEY App.</span><br />";
        // message += "There is an account previously registered with this email for iKEY App. Please login iKEY App using <b><u>previously registered</u></b> password in order to access iKEY services.";
        // message += "<br /><br /><span style='font-size:16px;font-weight:bold;'><table cellpadding='5' cellspacing='0' width='100%' border='0'><tbody>";
        // message += "<tr><td colspan='3' align='center'>Download the iKEY App now:</td></tr>";
        // message += "<tr>";
        // message += "<td align='right'><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://apps.apple.com/us/app/ikey-parent/id1476014901') + ">";
        // message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/app_store_logo.png' alt='app_store_logo' width='100%' /></button></td>";
        // message += "<td>&nbsp;&nbsp;</td>";
        // message += "<td><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + this.OpenNewWindow('https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full') + ">";
        // message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/google_play_logo.png' alt='google_play_logo' width='100%' /></button></td>";
        // message += "</tr>";
        // message += "<tr><td colspan='3' align='center'>to download.</td></tr>";
        // message += "</tbody></table></span>";
        return message;
    }

    //2021.08.18
    SendWelcomeEmailToNewParentRegistration = async () => {
        let isApiWorkflowSuccess = false;
        let message = '';

        //modal
        let emailModal = {
            name: this.state.signup_GuardianName,
            email: this.state.signupEmail,
            password: this.state.signupPassword,
        };

        let done = false;
        await fetch('https://ikey-general-task.azurewebsites.net/api/LiveQuizWelcomeMessage?code=v3RPnpZ2ZWJXq6jMPBSyjULMd/vRi9vIneT70mycBd3za00qyrgJFg==',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'no-cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin',                 // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                         // manual, *follow, error
                // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(emailModal), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(async (data) => {
                // await DelayUntil(() => data !== null);
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(data));

                // isApiWorkflowSuccess = true;
                if (data.success)
                    isApiWorkflowSuccess = true;

                done = true;
                message = 'api - send welcome email (' + (data.success ? 'success' : 'failed') + '). ' + JSON.stringify(data);
            })
            .catch(error => {
                done = true;
                message = 'api welcome email failed catch. ' + error.message;
            });
        await DelayUntil(() => done === true);

        if (this.props.isDevMode)
            console.log('api (response) =\n' + message);

        // if (isApiWorkflowSuccess === false) {
        //     // this.SetAlert('Error', errorMessage + '<br /><br />' + JSON.stringify(apiResponse));
        //     console.log('Send Email | Error', errorMessage + '\n\n' + JSON.stringify(apiResponse));
        // }

        return isApiWorkflowSuccess;
    }

    //2021.08.18
    WelcomeEmailSentConfirmation = async (_parentId, _parentUserId) => {
        if (_parentId !== 0 && _parentUserId !== 0) {
            await fetch(GlobalSetting.ApiUrl + 'Api/Parent/Account/LiveQuizPortal/WelcomeEmailSent/' + _parentId + '/' + _parentUserId,
                {
                    method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                    // mode: 'no-cors',                            // no-cors, *cors, same-origin
                    // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin',                 // include, *same-origin, omit
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    // redirect: 'follow',                         // manual, *follow, error
                    // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    // body: '',   // body data type must match "Content-Type" header
                })
                .then(res => res.json())
                .then(async (data) => {
                    if (this.props.isDevMode)
                        console.log(JSON.stringify(data));
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('api welcome email confirmation failed catch. \n\n' + error.message);
                });
        }
    }

    //2021.08.18
    UpdateParentFirebaseUserMapping = async (_parentId, _parentUserId, _isEmailSent) => {
        let canProceed = false;
        if (_parentId !== 0 && _parentUserId !== 0) {
            if (this.props.loginResponse !== null && this.props.loginResponse !== undefined) {
                if (this.props.loginResponse.hasOwnProperty('user')) {
                    if (this.props.loginResponse.user !== null && this.props.loginResponse.user !== undefined) {
                        if (this.props.loginResponse.user.hasOwnProperty('stsTokenManager')) {
                            if (this.props.loginResponse.user.stsTokenManager !== null && this.props.loginResponse.user.stsTokenManager !== undefined) {
                                canProceed = true;
                            }
                        }
                    }
                }
            }
        }

        if (canProceed === false)
            return null;

        let updateModel = {
            ParentId: Number(_parentId),
            ParentUserId: Number(_parentUserId),
            WelcomeEmailSent: CheckBoolean(_isEmailSent),

            FirebaseUserId: String(this.props.loginResponse.user.uid),
            // FirebaseToken: null,
            FirebaseAccessToken: CheckObjectNullValue(this.props.loginResponse.user.stsTokenManager, 'accessToken'),
            FirebaseRefreshToken: CheckObjectNullValue(this.props.loginResponse.user.stsTokenManager, 'refreshToken'),
            FirebaseLastLoginTime: CheckStringEmpty(this.props.loginResponse.user.lastLoginAt).slice(0, -3),
            FirebaseExpirationTime: CheckStringEmpty(this.props.loginResponse.user.stsTokenManager.expirationTime).slice(0, -3),
        };

        await fetch(GlobalSetting.ApiUrl + 'Api/Parent/Account/LiveQuizPortal/ParentFirebaseUserMapping/Update/',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'no-cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin',                 // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                         // manual, *follow, error
                // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(updateModel),   // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(async (data) => {
                if (this.props.isDevMode)
                    console.log(JSON.stringify(data));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('api fb user id update failed catch. \n\n' + error.message);
            });
    }

    CreateNewUserInFirebase = async () => {

        let IsCmsAccountCreationSuccess = false;
        let IsFirebaseAccountCreationSuccess = false;
        let FirebaseAccountCreation_ErrorCode = '';
        let FirebaseAccountCreation_ErrorMessage = '';
        let IsFirebaseProfileCreationSuccess = false;

        let done = false;

        //Create User with SignIn
        await this.props.auth
            .createUserWithEmailAndPassword(this.state.signupEmail, this.state.signupPassword)
            .then((userCredential) => {
                IsFirebaseAccountCreationSuccess = true;
                this.setState({ NewUserCredential: userCredential, });  //2022.01.10
                done = true;
            })
            .catch(error => {
                IsFirebaseAccountCreationSuccess = false;
                // if (result.Success) {
                //     isAccountCreationSuccess = true;    //2021.08.21 - for existing Firebase user.
                // }
                // else {
                //     // isAccountCreationSuccess = false;
                //     // this.SetAlertWithProgressBarLocal("Invalid SignUp", "Unable to create user.<br /><br />" + error.message, false);
                //     this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
                //         Locale("unable-to-create-user", this.props.Locale) + "<br /><br />" + error.message, false);
                // }
                FirebaseAccountCreation_ErrorCode = error.code;
                FirebaseAccountCreation_ErrorMessage = error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
        done = false;

        //2024.08.24 - account existed, check if profile exist.
        let IsFirebaseProfileSyncRequired = false;
        if (IsFirebaseAccountCreationSuccess === false) {
            if (FirebaseAccountCreation_ErrorCode === 'auth/email-already-in-use') {
                const _Uid = CheckNullValue(this.state.NewUserCredential?.uid ?? this.state.NewUserCredential?.user?.uid);
                // const _Uid = this.state.NewUserCredential !== null ?
                //     this.state.NewUserCredential.hasOwnProperty('user') ?
                //         this.state.NewUserCredential.user.hasOwnProperty('uid') ?
                //             String(this.state.NewUserCredential.user.uid)
                //             : null
                //         : null
                //     : null;
                // const _Uid = CheckNullValue(this.state.NewUserCredential?.user?.uid ?? null);
                if (this.props.isDevMode)
                    console.log('Check FS Profile = ' + this.state.signupEmail + ' | ' + _Uid);
                if (CheckNullValue(this.state.signupEmail) !== null) {
                    await this.props.firestore
                        .collection('User')
                        // .where('Uid', '==', _Uid)
                        .where('Email', '==', this.state.signupEmail)
                        .limit(1)
                        .get()
                        .then(querySnapshot => {
                            if (this.props.isDevMode)
                                console.log('Check FS Profile (response) =\n' + JSON.stringify(querySnapshot));
                            let dataArray = [];
                            if (querySnapshot !== null) {
                                querySnapshot.forEach((doc) => {
                                    dataArray.push(doc.data());
                                    // dataArray[dataArray.length - 1].Id = doc.id;    //Uid
                                });
                                if (dataArray.length > 0)
                                    IsFirebaseProfileCreationSuccess = true;
                                else
                                    IsFirebaseProfileSyncRequired = true;
                            }
                            else {
                                IsFirebaseProfileSyncRequired = true;
                            }
                            done = true;
                            if (this.props.isDevMode)
                                console.log('IsFirebaseProfileSyncRequired = ' + IsFirebaseProfileSyncRequired,
                                    '\n' + JSON.stringify(dataArray));
                        })
                        .catch(error => {
                            IsFirebaseProfileSyncRequired = true;
                            if (this.props.isDevMode)
                                console.log('Check FS Profile (error) =\n' + JSON.stringify(error));
                            done = true;
                            // if (this.props.isDevMode)
                            //     console.log(error.message);
                        });
                    await DelayUntil(() => done === true);
                    done = false;
                }
            }
        }
        await Delay(1000);

        //#region old codes - disabled 2024.08.24
        // //=== auto iKEY app registration === starts ===//
        // //2021.08.21 - added retry loop.
        // let retryCounter = 0;
        // let isEmailSent = false;
        // let result = { Success: false, ParentId: 0, ParentUserId: 0, IsParentPreExisted: false, ErrorMessage: 'error', OperationHalted: false, };
        // let retryRegistration = true;

        // //stopped auto-registration on 2023.05.03 by request.
        // IsCmsAccountCreationSuccess = true;
        // retryRegistration = false;

        // if (retryRegistration) {
        //     do {
        //         // retryRegistration = true;
        //         retryCounter += 1;
        //         //2021.08.17
        //         isEmailSent = false;
        //         result = await this.NewParentRegistrationWorkflowViaLiveQuizPortal();   //regeister workflow for iKey app's new parent account.
        //         if (this.props.isDevMode)
        //             console.log(JSON.stringify(result));
        //         if (result.Success) {
        //             retryRegistration = false;  //stop retry loop.
        //             if (result.OperationHalted === false) {
        //                 IsCmsAccountCreationSuccess = true;

        //                 // console.log('\n(Api Create done)\nParentId = ' + result.ParentId + '\nParentUserId = ' + result.ParentUserId + '\nIsParentPreExisted = ' + result.IsParentPreExisted);
        //                 if (result.IsParentPreExisted) {
        //                     isEmailSent = true;
        //                     // isAccountCreationSuccess = true;
        //                 }
        //                 else {
        //                     isEmailSent = await this.SendWelcomeEmailToNewParentRegistration();   //welcome email.
        //                 }

        //                 //Set EmailSent Confirmation.
        //                 if (isEmailSent) {
        //                     await this.WelcomeEmailSentConfirmation(result.ParentId, result.ParentUserId);  //mark as Sent in CMS.
        //                 }
        //                 if (this.props.isDevMode) {
        //                     if (isEmailSent)
        //                         console.log('welcome email sent.');
        //                     else
        //                         // this.props.SetAlert('', '(API) welcome email send failed.', false);
        //                         console.log('(API) welcome email send failed.');
        //                 }
        //             }
        //         } else {
        //             //Failed.
        //             let error = result.ErrorMessage.toLowerCase();
        //             if (error === 'contact number has been registered' || (error.includes('contact number') && error.includes('registered'))) {
        //                 //add a random sigit [0 ~ 9] at the end.
        //                 let _NewContactNumber = this.state.signupContactNumber;
        //                 _NewContactNumber += Math.floor(Math.random() * 10);   //random [0,1,2,3,4,5,6,7,8,9]
        //                 this.setState({ signupContactNumber: _NewContactNumber });
        //             }
        //             else if (error === 'phone number wrong format' || (error.includes('phone number') && error.includes('wrong format'))) {
        //                 let _ContactNumber = CheckStringEmpty(this.state.signupContactNumber);

        //                 // if (_ContactNumber.includes('-')) {
        //                 //     _ContactNumber = _ContactNumber.replace('-', '');
        //                 // }
        //                 // if (_ContactNumber.includes('＋')) {    //special symbol＋, not normal +
        //                 //     _ContactNumber = _ContactNumber.replace('＋', '');
        //                 // }
        //                 // if (_ContactNumber.substr(0, 1).includes('6')) {    //e.g. 60xxxxx
        //                 //     _ContactNumber = '+' + _ContactNumber;
        //                 // }
        //                 // if (_ContactNumber.includes('+60') === false && _ContactNumber.includes('+0')) {  //e.g. +0xxx
        //                 //     _ContactNumber = _ContactNumber.replace('+0', '+60');
        //                 // }
        //                 // // let regexContactNumber = /^(\+?6?01)[0-46-9][0-9]{7,8}$/;
        //                 // // let regexContactNumber = /^[+]\d{5,15}$/;
        //                 // // if (regexContactNumber.test(_ContactNumber) === false) {
        //                 // //     //
        //                 // // }


        //                 //2021.08.28
        //                 //only keeps 0~9.
        //                 let arrayValue = Array.from(_ContactNumber);
        //                 let newArrayValue = [];
        //                 arrayValue.map((data, key) => {
        //                     let index = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].findIndex(x => x === data);
        //                     if (index > -1) {
        //                         newArrayValue.push(data);
        //                     }
        //                     return null;
        //                 });
        //                 _ContactNumber = newArrayValue.join('');
        //                 if (_ContactNumber.charAt(0).includes('0')) {    //first digit of the contact number, e.g. 0xxxxx
        //                     _ContactNumber = _ContactNumber.slice(1, _ContactNumber.length);
        //                 }
        //                 _ContactNumber = '+' + _ContactNumber;
        //                 if (_ContactNumber.length > 15) {
        //                     let sliced = _ContactNumber.slice(14, _ContactNumber.length);
        //                     if (sliced.length > 1) {
        //                         // _ContactNumber = sliced;
        //                         _ContactNumber = _ContactNumber.replace(sliced, Math.floor(Math.random() * 10));
        //                     }
        //                 }
        //                 this.setState({ signupContactNumber: _ContactNumber });
        //             }
        //             else {
        //                 if (result.OperationHalted === false) {
        //                     this.SetAlertLocal(Locale("invalid-signup", this.props.Locale),
        //                         '(API) ' + Locale("unable-to-create-user", this.props.Locale)
        //                         + '<br /><br /><span class="blink-fast" style="font-weight:bold;color:red;">(Error) ' + result.ErrorMessage + '</span>'
        //                         // + '<br /><br />(Error) ' + result.ErrorMessage
        //                         + '<br /><br />' + Locale("auto-reg-try-again-message", this.props.Locale)
        //                     );
        //                     // return null;
        //                 }
        //                 // else {
        //                 //     retryRegistration = true;   //exit loop.
        //                 // }
        //                 return null;
        //             }
        //         }
        //         // return null;
        //         if (retryCounter > 5) {
        //             retryRegistration = false;
        //         }
        //         if (retryRegistration) {
        //             await Delay(500);
        //         }
        //     } while (retryRegistration);
        // }
        // //=== auto iKEY app registration === ends ===//
        //#endregion

        //#region old codes.
        // if (result.OperationHalted)
        //     return null;

        //==== moved to top === 2021.08.23 ===//
        // let isAccountCreationSuccess = null;
        // if (result.OperationHalted === false) {
        //     //Create User with SignIn
        //     await this.props.auth
        //         .createUserWithEmailAndPassword(this.state.signupEmail, this.state.signupPassword)
        //         .then(() => {
        //             isAccountCreationSuccess = true;
        //         })
        //         .catch(error => {
        //             isAccountCreationSuccess = false;
        //             if (result.Success) {
        //                 isAccountCreationSuccess = true;    //2021.08.21 - for existing Firebase user.
        //             }
        //             else {
        //                 // isAccountCreationSuccess = false;
        //                 // this.SetAlertWithProgressBarLocal("Invalid SignUp", "Unable to create user.<br /><br />" + error.message, false);
        //                 this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
        //                     Locale("unable-to-create-user", this.props.Locale) + "<br /><br />" + error.message, false);
        //             }
        //         });
        // }
        // else {
        //     isAccountCreationSuccess = true;
        // }

        // if (isAccountCreationSuccess == null) {
        //     // this.SetAlertWithProgressBarLocal("Invalid SignUp", "Unable to create user. <b>NULL</b>", false);
        //     // this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
        //     //     Locale("unable-to-create-user", this.props.Locale) + "<b>NULL</b>", false);
        //     this.SetAlert(Locale("invalid-signup", this.props.Locale),
        //         Locale("unable-to-create-user", this.props.Locale) + "<b>NULL</b>");
        // }
        // else if (!isAccountCreationSuccess) {
        //     // this.SetAlert(Locale("invalid-signup", this.props.Locale),
        //     //     Locale("unable-to-create-user", this.props.Locale));
        // }
        // else {
        // if (IsFirebaseAccountCreationSuccess) {
        //Background SignIn with new created account.

        // let isDone = await this.NewUserSetup();

        // if (isDone) {
        //     // this.SetAlertWithProgressBarLocal("Creating user profile", "Please wait patiently.", true);
        //     this.SetAlertWithProgressBarLocal(Locale("creating-user-profile", this.props.Locale),
        //         Locale("please-wait", this.props.Locale), true);

        //     // setTimeout(() => {
        //     //     this.SetAlertWithProgressBarLocal("Creating user profile...", "Please wait patiently.", true);
        //     // }, 1000);

        //     // setTimeout(async () => {
        //     //     await this.CreateNewUserProfileInFirebase();
        //     // }, 1500);

        //     //2021.08.18
        //     await this.UpdateParentFirebaseUserMapping(result.ParentId, result.ParentUserId, isEmailSent);
        //     await Delay(1000);
        //     await this.CreateNewUserProfileInFirebase(result.IsParentPreExisted);
        // }
        //#endregion

        //#region old codes - disabled 2024.08.24
        // //2021.08.21
        // await this.props.newUser();
        // await this.props.login(this.state.signupEmail, this.state.signupPassword, false, false);

        // //2023.10.31 - revampe.
        // await DelayUntil(() => this.props.isLoginDone === true);
        // const loginProgressCompleted = CheckNullValue(this.props.user) !== null && CheckNullValue(this.props.loginResponse) !== null;

        // // let loginProgressCompleted =
        // //     this.props.user !== undefined
        // //     && this.props.loginResponse !== undefined
        // //     && this.props.user !== null
        // //     && this.props.loginResponse !== null;
        // // await DelayUntil(() => this.props.user !== undefined && this.props.loginResponse !== undefined);

        // await Delay(1000);

        // // if (this.props.isDevMode)
        // //     console.log('loginSuccess = ' + loginProgressCompleted);
        //#endregion

        // if (loginProgressCompleted) {
        if (IsFirebaseProfileSyncRequired) {

            await this.props.newUser();     //2024.08.24

            this.SetAlertWithProgressBarLocal('',
                Locale("creating-user-profile", this.props.Locale) + '<br />' + Locale("please-wait", this.props.Locale),
                true);

            //disabled 2024.08.24 - not required anymore.
            // //2021.08.18
            // if (result.ParentId > 0 && result.ParentUserId > 0)
            //     await this.UpdateParentFirebaseUserMapping(result.ParentId, result.ParentUserId, isEmailSent);
            // await Delay(1000);
            // // await this.CreateNewUserProfileInFirebase(
            // //     result.IsParentPreExisted,
            // //     IsFirebaseAccountCreationSuccess, IsCmsAccountCreationSuccess);

            //2024.08.24
            if (this.state.NewUserCredential === null) {
                const { apiDone, success, userRecord } = await this.GetIdentity();
                await DelayUntil(() => apiDone === true);
                if (success) {
                    IsFirebaseAccountCreationSuccess = true;
                    FirebaseAccountCreation_ErrorCode = '';
                    this.setState({
                        NewUserCredential: userRecord,
                    });
                }
            }

            IsFirebaseProfileCreationSuccess = await this.CreateNewUserProfileInFirebase();
        }
        // else {
        //     if (result.Success) {
        //         this.props.SetAlertConfirm('Notice',
        //             this.GetAutoRegSuccessMessage_ExstingFirebaseUser(),
        //             this.props.Feedback.Confirm, false, false, true, false, false);
        //     }
        // }
        // }
        // else {
        //     //Firebase account creation failed (existed or fault)
        //     // if (result.Success) {
        //     //     this.props.SetAlertConfirm('Notice',
        //     //         this.GetAutoRegSuccessMessage_ExstingFirebaseUser(),
        //     //         this.props.Feedback.Confirm, false, false, true, false, false);
        //     // }
        //     // else {
        //     this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
        //         Locale("unable-to-create-user", this.props.Locale) + "<br /><br />Error Code: 10007", false);
        //     // }
        // }
        // if (this.props.isDevMode)
        //     console.log('IsFirebaseProfileCreationSuccess = ' + IsFirebaseProfileCreationSuccess);

        //2022.10.11
        await Delay(1000);
        if (IsFirebaseProfileCreationSuccess) {
            const syncProfileDone = await this.SyncProfileToBase();
            await DelayUntil(() => syncProfileDone === true);
        }

        setTimeout(() => {
            //#region old codes.
            // this.GotoLoginPage();

            // //2021.08.19
            // if (IsFirebaseAccountCreationSuccess && IsCmsAccountCreationSuccess) {
            //     //Firebase User = New, CMS Parent User = New,
            //     //Firebase User login = Success, CMS Parent User creation = Success,

            //     this.props.SetAlertConfirm('Notice',
            //         this.GetAutoRegSuccessMessage(),
            //         this.props.Feedback.Confirm, false, false, true, false, false);
            // }
            // else if (IsFirebaseAccountCreationSuccess === false && result.IsParentPreExisted) {

            //     //Firebase User = Existed, CMS Parent User = Existed,
            //     //Firebase User login = Success, CMS Parent User creation = failed,

            //     //Firebase User = Existed, CMS Parent User = Existed,
            //     //Firebase User login = wrong password, CMS Parent User creation = failed,

            //     this.props.SetAlertConfirm('Notice',
            //         this.GetAutoRegSuccessMessage_ExstingFirebaseUser(),
            //         this.props.Feedback.Confirm, false, false, true, false, false);
            // }
            // else if (IsFirebaseAccountCreationSuccess && result.IsParentPreExisted) {
            //     this.props.SetAlertConfirm('Notice',
            //         this.GetAutoRegSuccessMessage_NewFirebaseUser_ExstingParentUser(),
            //         this.props.Feedback.Confirm, false, false, true, false, false);
            // }
            // else if (IsFirebaseAccountCreationSuccess === false && IsCmsAccountCreationSuccess === false) {
            //     this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //         Locale("unable-to-create-user", this.props.Locale) + "<br /><br />Error Code: 10006", false);
            // }
            // else {
            //     //both FB & CMS - creation failed.

            //     //Firebase User = Existed, CMS Parent User = New,
            //     //Firebase User creation = Success, CMS Parent User creation = Success,

            //     this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //         Locale("unable-to-create-user", this.props.Locale) + "<br /><br />Error Code: 10005", false);
            // }
            //#endregion

            //2023.05.03
            if (IsFirebaseAccountCreationSuccess && IsFirebaseProfileCreationSuccess) {
                this.props.SetAlertConfirm('Notice',
                    this.GetCommonRegSuccessMessage(),
                    this.props.Feedback.Confirm, false, false, true, false, false);
            }
            else {
                // this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
                //     Locale("unable-to-create-user", this.props.Locale) + "<br /><br />Error Code: 10002", false);
                if (FirebaseAccountCreation_ErrorCode !== '') {
                    //error registration.
                    this.props.SetAlert(Locale("invalid-signup", this.props.Locale),
                        "Error Code: " + FirebaseAccountCreation_ErrorCode + "<br /><br />" + FirebaseAccountCreation_ErrorMessage, false);
                }
                else {
                    //for existed account(s).
                    this.props.SetAlertConfirm('Notice',
                        this.GetAutoRegSuccessMessage_HasFirebaseUser_HasParentUser(),
                        this.props.Feedback.Confirm, false, false, true, false, false);
                }
            }

            //#region old codes - auto-reg stopped by request 2023.05.03
            //2021.08.23
            // if (IsFirebaseAccountCreationSuccess) {
            //     if (IsCmsAccountCreationSuccess) {
            //         this.props.SetAlertConfirm('Notice',
            //             this.GetAutoRegSuccessMessage(),
            //             this.props.Feedback.Confirm, false, false, true, false, false);
            //     }
            //     else {
            //         if (result.IsParentPreExisted) {
            //             //Brand New FB & Existed Parent.

            //             // this.props.SetAlertConfirm('Notice',
            //             //     this.GetAutoRegSuccessMessage_NewFirebaseUser_ExstingParentUser(),
            //             //     this.props.Feedback.Confirm, false, false, true, false, false);
            //             // this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //             //     "testing<br /><br />Error Code: 10001", false);

            //             this.props.SetAlertConfirm('Notice',
            //                 this.GetAutoRegSuccessMessage_NewFirebaseUser_HasParentUser(),  //this.props.loginState),
            //                 this.props.Feedback.Confirm, false, false, true, false, false);
            //         }
            //         else {
            //             // this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //             //     "iKEY App account registration failed.<br /><br />Error Code: 10001", false);
            //             this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //                 "testing<br /><br />Error Code: 10002", false);
            //         }
            //     }
            // }
            // else {
            //     if (IsCmsAccountCreationSuccess) {
            //         // if (result.IsParentPreExisted) {
            //         //     this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //         //         "testing<br /><br />Error Code: 10003", false);
            //         // }
            //         // else {
            //         //Existed FB account & login success, New/Existed Parent account.
            //         this.props.SetAlertConfirm('Notice',
            //             this.GetAutoRegSuccessMessage_HasFirebaseUser_NewParentUser(),  //this.props.loginState),
            //             this.props.Feedback.Confirm, false, false, true, false, false);
            //         // }
            //     }
            //     else {
            //         if (result.IsParentPreExisted) {
            //             // this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //             //     "testing<br /><br />Error Code: 10003", false);

            //             // this.props.SetAlertConfirm('Notice',
            //             //     this.GetAutoRegSuccessMessage_HasFirebaseUser_HasParentUser(),  //this.props.loginState),
            //             //     this.props.Feedback.Confirm, false, false, true, false, false);

            //             //2021.08.25
            //             if (FirebaseAccountCreation_ErrorCode !== '') {
            //                 this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //                     "Error Code: " + FirebaseAccountCreation_ErrorCode + "<br /><br />" + FirebaseAccountCreation_ErrorMessage, false);
            //             }
            //             else {
            //                 this.props.SetAlertConfirm('Notice',
            //                     this.GetAutoRegSuccessMessage_HasFirebaseUser_HasParentUser(),  //this.props.loginState),
            //                     this.props.Feedback.Confirm, false, false, true, false, false);
            //             }
            //         }
            //         else {
            //             if (FirebaseAccountCreation_ErrorCode !== '') {
            //                 this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //                     "Error Code: " + FirebaseAccountCreation_ErrorCode + "<br /><br />" + FirebaseAccountCreation_ErrorMessage, false);
            //             }
            //             else {
            //                 this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //                     "testing<br /><br />Error Code: 10004", false);
            //             }
            //         }
            //     }
            // }
            //#endregion

        }, 2000);

        //end.
        if (this.props.isDevMode) {
            console.log('CreateNewUserInFirebase',
                // '\nIsCmsAccountCreationSuccess = ' + IsCmsAccountCreationSuccess,
                '\nIsFirebaseAccountCreationSuccess = ' + IsFirebaseAccountCreationSuccess,
                '\nIsFirebaseProfileCreationSuccess = ' + IsFirebaseProfileCreationSuccess,
                '\nFirebaseAccountCreation_ErrorCode = ' + FirebaseAccountCreation_ErrorCode,
                '\nFirebaseAccountCreation_ErrorMessage = ' + FirebaseAccountCreation_ErrorMessage
            );
        }
    }

    //2022.10.11
    SyncProfileToBase = async () => {
        try {
            //2022.01.10 - Sync Profile to Base.
            if (this.state.NewUserCredential !== null) {

                //2024.08.24
                const _Uid = CheckNullValue(this.state.NewUserCredential?.uid ?? this.state.NewUserCredential?.user?.uid);
                // let _Uid = this.state.NewUserCredential.hasOwnProperty('user') ?
                //     this.state.NewUserCredential.user.hasOwnProperty('uid') ?
                //         String(this.state.NewUserCredential.user.uid)
                //         : 'null'
                //     : 'null';

                let _OrganizerIdentity = this.props.OrganizerIdentity !== null && this.props.OrganizerIdentity !== undefined ?
                    String(this.props.OrganizerIdentity)
                    : 'null';
                let _regDate = this.state.regCurrMomentUtc === null ? 'null' : moment(this.state.regCurrMomentUtc).format('YYYY-MM-DD HH:mm:ss.sss');

                //2022.06.16
                const regData = localStorage.getItem('RgistrationDataViaLoginEventList');
                const regDataViaLogin = regData === null ? null : JSON.parse(regData);
                // SetContext('EventCode', regDataViaLogin.EventCode);
                // SetContext('AuthorId', regDataViaLogin.AuthorId);
                // SetContext('CenterUserId', regDataViaLogin.CenterUserId);
                let _eventCde = '';
                let _centerUserId = 0;
                let _authorId = 0;
                if (regDataViaLogin !== null) {
                    if (regDataViaLogin.hasOwnProperty('EventCode')) {
                        // if (this.props.eventCode !== null && this.props.eventCode !== undefined && String(this.props.eventCode).length > 0)
                        if (CheckNullValue(this.props.eventCode) !== null)
                            _eventCde = String(this.props.eventCode);
                        else
                            _eventCde = String(regDataViaLogin.EventCode);
                    }
                    if (regDataViaLogin.hasOwnProperty('CenterUserId')) {
                        if (this.props.evtCenterUserId !== null && this.props.evtCenterUserId !== undefined && Number(this.props.evtCenterUserId) > 0)
                            _centerUserId = Number(this.props.evtCenterUserId);
                        else
                            _centerUserId = Number(regDataViaLogin.CenterUserId);
                    }
                    if (regDataViaLogin.hasOwnProperty('AuthorId')) {
                        if (this.props.evtAuthorId !== null && this.props.evtAuthorId !== undefined && Number(this.props.evtAuthorId) > 0)
                            _authorId = Number(this.props.evtAuthorId);
                        else
                            _authorId = Number(regDataViaLogin.AuthorId);
                    }
                }

                //Sync to Base.
                // if (_Uid.length > 0 && _Uid !== 'undefined' && _Uid !== 'null') {
                if (CheckNullValue(_Uid) !== null) {
                    try {
                        await fetch(GlobalSetting.ApiUrl
                            + 'Api/LearningCentre/User/Registration/Profile/Sync',
                            // + 'Api/LearningCentre/User/Profile/Sync/',
                            // + _Uid + '/' + _OrganizerIdentity + '/' + String(this.props.eventCode) + '/' + _regDate

                            // + '/' + String(this.props.evtCenterUserId) + '/' + String(this.props.evtAuthorId),
                            //+ '/null/null',

                            // Api/LearningCentre/User/Profile/Sync/{firebaseUserId}/{organizerIdentity}/{eventCode}/{registrationDate}/{centerUserId}/{authorId}
                            {
                                // method: 'GET',                             // *GET, POST, PUT, DELETE, etc.
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    FirebaseUserId: String(_Uid),
                                    OrganizerIdentity: String(_OrganizerIdentity),
                                    // EventCode: String(this.props.eventCode),
                                    // CenterUserId: String(this.props.evtCenterUserId),
                                    // AuthorId: String(this.props.evtAuthorId),
                                    EventCode: _eventCde,
                                    CenterUserId: _centerUserId,
                                    AuthorId: _authorId,
                                    RegistrationDate: String(_regDate),

                                    // //2023.10.31
                                    // SignUpPassword: this.state.signupPassword,
                                    // SignUpEmail: this.state.signupEmail,

                                    //2024.05.06
                                    Email: this.state.signupEmail,

                                    //2024.08.24
                                    Password: this.state.signupPassword,
                                })
                            })
                            .then(res => res.json())
                            .then((data) => {
                                if (!data.success) {
                                    if (this.props.isDevMode)
                                        console.log('api - profile - sync (failed). | ' + JSON.stringify(data));
                                }
                                else {
                                    localStorage.removeItem('RgistrationDataViaLoginEventList');
                                }
                            })
                            .catch(error => {
                                if (this.props.isDevMode)
                                    console.log('api - profile - sync (failed). | ' + error.message);
                                return true;
                            });
                    }
                    catch (error) {
                        if (this.props.isDevMode)
                            console.log('api - profile - sync (failed). | ' + error.message);
                        return true;
                    }
                }
            }
        }
        catch (e) {
            if (this.props.isDevMode)
                console.log(e.message);
            return true;
        }
        return true;
    }

    // NewUserSetup = async () => {
    //     await this.props.newUser();
    //     let success = await this.props.login(this.state.signupEmail, this.state.signupPassword);
    //     await DelayUntil(() => this.props.loginResponse !== null || this.props.loginResponse !== undefined);
    //     await Delay(1000);
    //     return success;
    // }

    //2024.08.24
    GetIdentity = async () => {
        let success = false;
        let done = false;
        let userRecord = null;
        await fetch(GlobalSetting.ApiUrl + `Api/LearningCentre/User/GetIdentity?email=${this.state.signupEmail}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then((data) => {
                if (this.props.isDevMode)
                    console.log('GetIdentity (data) =\n' + JSON.stringify(data));
                const response = JSON.parse(JSON.stringify(data));
                // if (this.props.isDevMode)
                //     console.log('GetIdentity (response) =\n' + JSON.stringify(response));
                success = CheckBoolean(response?.success);
                userRecord = CheckNullValue(response?.data);
                // if (this.props.isDevMode) {
                //     console.log('GetIdentity (success) =\n' + JSON.stringify(success));
                //     console.log('GetIdentity (userRecord) =\n' + JSON.stringify(userRecord));
                // }
                done = true;
            })
            .catch(error => {
                done = true;
            });
        await DelayUntil(() => done === true);
        return { apiDone: done, success, userRecord };
    }

    // CreateNewUserProfileInFirebase = async (IsParentPreExisted = true, IsFirebaseAccountCreationSuccess = true, IsCmsAccountCreationSuccess = true) => {
    CreateNewUserProfileInFirebase = async () => {

        //2024.08.24
        // const userUid = this.state.NewUserCredential !== null ?
        //     this.state.NewUserCredential.hasOwnProperty('user') ?
        //         this.state.NewUserCredential.user.hasOwnProperty('uid') ?
        //             String(this.state.NewUserCredential.user.uid)
        //             : null
        //         : null
        //     : null;
        const userUid = CheckNullValue(this.state.NewUserCredential?.uid ?? this.state.NewUserCredential?.user?.uid);
        if (this.props.isDevMode)
            console.log('CreateNewUserProfileInFirebase (userUid) = ' + userUid);

        //Create in Firebase, Realtime Databse = older app, FireStore = future proves.
        if (CheckNullValue(userUid) !== null) {
            // if (this.props.user !== null) {
            // let errorMessage = "";

            // //2020.12.04
            // if (this.props.isEventRegistration) {
            //     this.setState({
            //         signupStudentCenter: this.props.centerName,
            //     });
            // }

            //2020.11.28
            // const userUid = String(this.props.user.uid);
            let currentMoment = moment();
            let currentMomentUtc = currentMoment.utc();
            let currentDateUtc = currentMomentUtc.format("YYYY-MM-DD HH:mm:ss");
            // let currentDate = currentMoment.format("YYYY-MM-DD HH:mm:ss");
            this.setState({ regCurrMomentUtc: currentMomentUtc, });

            //Create in Realtime Database.
            // if (!this.state.isRealtimeProfileExists) {
            await this.props.dbCommon.ref("Common/" + userUid)
                .set({
                    _0LastUpdate: currentDateUtc,
                    _1Name: this.state.signupStudentName,
                    _2Grade: this.state.signupGradeSelection,
                    _3Center: this.state.signupStudentCenter,
                    _4Score: 0,
                    _5Mode: "Student",
                    _6ICNo: "",
                    _7Email: this.state.signupEmail,
                    _8Contact: this.state.signupContactNumber,      //2020.11.21

                    //2020.11.26
                    _9Guardian: this.state.signup_GuardianName,
                    _10School: this.state.signup_SchoolName,
                    _11PolicyTncAgree: this.state.signup_AgreePolicyTnc,
                    _12PolicyTncAgreeDateTime: currentDateUtc,
                    // _12PolicyTncAgreeDateTime: currentDate,     //2022.11.04
                    _13NationalState: this.state.signup_NationalState,

                    //2020.11.28
                    _14Below13: this.state.toggleShowHide_GuardianNameField,

                    //2020.12.03
                    // _15SimStudent: this.state.signup_CenterStudent === 'Yes' ? true : false,

                    //2020.12.15
                    _15Uid: userUid,

                    //2021.07.30
                    _16Gender: this.state.signup_Gender,
                    _17Race: this.state.signup_Race,
                    // _18Religion: this.state.signup_Religion,

                    //2021.10.12
                    _18DistrictArea: this.state.signup_DistrictArea,

                    //2023.06.14
                    _19Classroom: this.state.signup_Classroom,
                })
                .then(() => {
                    // isRealtimeDatabaseCreateSuccess = true;
                    this.setState({
                        isRealtimeProfileExists: true,
                    });
                })
                .catch(error => {
                    // errorMessage += "Realtime : " + error.code + "<br />" + error.message + "<br /><br />";
                });
            // }

            //Create in FireStore.
            // if (!this.state.isFireStoreProfileExists) {
            let _FS_Modal = {
                LastUpdate: currentDateUtc,
                Name: this.state.signupStudentName,
                Grade: this.state.signupGradeSelection,
                Center: this.state.signupStudentCenter,
                Score: 0,
                Mode: "Student",
                ICNo: "",
                Email: this.state.signupEmail,
                Contact: this.state.signupContactNumber,    //2020.11.21

                //2020.11.26
                Guardian: this.state.signup_GuardianName,
                School: this.state.signup_SchoolName,
                PolicyTncAgree: this.state.signup_AgreePolicyTnc,
                PolicyTncAgreeDateTime: currentDateUtc,
                NationalState: this.state.signup_NationalState,

                //2020.11.28
                Below13: this.state.toggleShowHide_GuardianNameField,

                //2020.12.03
                // SimStudent: this.state.signup_CenterStudent === 'Yes' ? true : false,

                //2020.12.15
                Uid: userUid,

                //2021.07.30
                Gender: this.state.signup_Gender,
                Race: this.state.signup_Race,
                // Religion: this.state.signup_Religion,

                //2021.10.12
                DistrictArea: this.state.signup_DistrictArea,

                //2023.06.14
                Classroom: this.state.signup_Classroom,
            };
            await this.props.firestore
                .collection('User')
                .doc(userUid)
                .set(_FS_Modal)
                .then(() => {
                    // isFireStoreCreateSuccess = true;
                    this.setState({
                        isFireStoreProfileExists: true,
                    });
                })
                .catch(error => {
                    // errorMessage += "FireStore : " + error.code + "<br />" + error.message + "<br /><br />";
                });
            // }

            //2020.11.28
            //Create in FireStore
            //=== Save Policy Tnc agreement status. === starts ===//

            //fetch Policy Tnc Version settings.
            let _version = 1;
            // let _published = '';
            // let _fetchSuccess = false;
            await this.props.firestore
                .collection('LiveQuizPolicyTncAgreement')
                .where('Search', '==', 'Setting')
                .get()
                .then((querySnapshot) => {
                    let data = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                    }
                    if (data.length > 0) {
                        _version = data[0]['LatestVersion'];
                        // _published = data[0]['LatestPublished'];
                        // _fetchSuccess = true;
                    }
                    // temp1 = JSON.stringify(querySnapshot);
                })
                .catch(async (error) => {
                    await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | CheckPolicyTncAgreeState | Setting | Error = " + error);
                });

            // if (_fetchSuccess) {
            //Save to user's Policy Tnc agreement.
            await this.props.firestore.collection('LiveQuizPolicyTncAgreement')
                .doc(userUid)
                .set({
                    LastUpdate: currentDateUtc,
                    Uid: userUid,
                    Version: _version,
                })
                .catch(async (error) => {
                    await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Policy | User | Error = " + error);
                });
            // }

            //Save to user's Policy Tnc agreement List.
            await this.props.firestore.collection('LiveQuizPolicyTncAgreement')
                .doc(userUid)
                .collection('List')
                .doc(currentMomentUtc.format('YYYYMMDDHHmmss'))
                .set({
                    DateTime: currentDateUtc,
                    Version: _version,
                })
                .catch(async (error) => {
                    await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Policy | User (List) | Error = " + error);
                });

            //=== Save Policy Tnc agreement status. === ends ===//


            //not need as in RegistrationData also keep this info.
            // //2020.12.04
            // //=== Save Is Center Student status = is isEventRegistration = independantly ===//
            // if (this.props.isEventRegistration) {
            //     await this.props.firestore.collection('LiveQuizPolicyTncAgreement')
            //         .doc(this.props.user.uid)
            //         .collection('List')
            //         .doc(currentMoment.format('YYYYMMDDHHmmss'))
            //         .set({
            //             DateTime: currentDate,
            //             Version: _version,
            //         })
            //         .catch(async (error) => {
            //             await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Policy | User (List) | Error = " + error);
            //         });
            // }
            // //=== Save Is Center Student status = ends ===//



            //2020.12.03
            //=== Save profile modal = again === in extra location for future reference ===//
            // await this.props.SetErrorLog(new Date(), "New User Profile Modal = " + JSON.stringify(_FS_Modal));
            // await this.props.SetLog("New User Profile Modal = " + JSON.stringify(_FS_Modal));

            //2020.12.04
            let _FS_Modal_Reg = JSON.parse(JSON.stringify(_FS_Modal));

            //remove not needed property.
            delete _FS_Modal_Reg.LastUpdate;    //replace property LastUpdate with CreatedDate.
            delete _FS_Modal_Reg.Score;
            // delete _FS_Modal_Reg.ICNo;
            delete _FS_Modal_Reg.Mode;

            //assign value.
            _FS_Modal_Reg['Center'] = this.props.isEventRegistration ? this.props.centerName : this.state.signupStudentCenter;

            //add extra property.
            _FS_Modal_Reg['CreatedDate'] = currentDateUtc;
            _FS_Modal_Reg['PolicyTncAgreeVersion'] = _version;
            _FS_Modal_Reg['IsEventRegistration'] = this.props.isEventRegistration;
            _FS_Modal_Reg['EventName'] = this.props.eventName;
            _FS_Modal_Reg['EventCode'] = this.props.eventCode;     //2021.01.08
            _FS_Modal_Reg['IsCenterStudent'] = this.state.signup_CenterStudent;
            _FS_Modal_Reg['Uid'] = userUid;

            //#region old codes.

            // await this.props.firestore.collection('RegistrationData')
            //     .doc(this.props.user.uid)
            //     .set(_FS_Modal_Reg)
            //     .catch(async (error) => {
            //         await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | Error = " + error);
            //     });

            //2022.07.29 - save to new collection. old codes obsolete & disabled.
            // //2020.12.14
            // // let _eventName = String(this.props.eventName).replace(new RegExp(' ', 'g'), '');
            // let _eventCode = String(this.props.eventCode).toLowerCase();
            // let _centerName = String(this.props.centerName).toLowerCase();


            // if (_eventCode.includes('abacus') && _centerName.includes('newton')) {
            //     //New property.
            //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;

            //     // //Smart Newton = Abacus Challenge.
            //     // await this.props.firestore.collection('SmartNewton_RegistrationData')
            //     //     .doc(this.props.user.uid)
            //     //     .set(_FS_Modal_Reg)
            //     //     .catch(async (error) => {
            //     //         await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | Error = " + error);
            //     //     });

            //     //2021.01.14    //new FS location.
            //     await this.props.firestore
            //         .collection('LiveQuiz_RegData')
            //         .doc('SMARTNEWTON')
            //         .collection('List')
            //         .doc(this.props.user.uid)
            //         .set(_FS_Modal_Reg)
            //         .catch(async (error) => {
            //             await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | UID: " + this.props.user.uid + " | Error = " + error);
            //         });
            // }
            // else if (_eventCode.includes('colin') || _centerName.includes('colin')) {
            //     //edited on 2021.01.18    //new FS location.

            //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;
            //     //COLIN = SPOT 2020/2021.
            //     await this.props.firestore
            //         .collection('LiveQuiz_RegData')
            //         .doc('COLIN')
            //         .collection('List')
            //         .doc(this.props.user.uid)
            //         .set(_FS_Modal_Reg)
            //         .catch(async (error) => {
            //             await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | UID: " + this.props.user.uid + " | Error = " + error);
            //         });
            // }
            // else if (_eventCode.includes('spot')) {
            //     // //SIM = SPOT 2020/2021.
            //     // await this.props.firestore.collection('RegistrationData')
            //     //     .doc(this.props.user.uid)
            //     //     .set(_FS_Modal_Reg)
            //     //     .catch(async (error) => {
            //     //         await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | Error = " + error);
            //     //     });

            //     // //2021.01.14    //new FS location.
            //     // if (_eventCode.includes('colin') || _centerName.includes('colin')) {
            //     //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;
            //     //     //COLIN = SPOT 2020/2021.
            //     //     await this.props.firestore
            //     //         .collection('LiveQuiz_RegData')
            //     //         .doc('COLIN')
            //     //         .collection('List')
            //     //         .doc(this.props.user.uid)
            //     //         .set(_FS_Modal_Reg)
            //     //         .catch(async (error) => {
            //     //             await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | UID: " + this.props.user.uid + " | Error = " + error);
            //     //         });
            //     // }
            //     // else {
            //     _FS_Modal_Reg.Group = this.state.signupGradeSelection;
            //     //SIM = SPOT 2020/2021.
            //     await this.props.firestore
            //         .collection('LiveQuiz_RegData')
            //         .doc('SIM')
            //         .collection('List')
            //         .doc(this.props.user.uid)
            //         .set(_FS_Modal_Reg)
            //         .catch(async (error) => {
            //             await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | UID: " + this.props.user.uid + " | Error = " + error);
            //         });
            //     // }
            // }

            //2022.07.29 - save to new collection. old codes obsolete & disabled.
            // //2021.02.17
            // let _targetRepo = '';
            // if (_eventCode.includes('abacus') && _centerName.includes('newton')) {
            //     //SMARTNEWTON = ABACUS CHALLENGE
            //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;
            //     _targetRepo = 'SMARTNEWTON';
            // }
            // else if (_eventCode.includes('colin') || _centerName.includes('colin')) {
            //     //COLIN = SPOT 2020/2021.
            //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;
            //     _targetRepo = 'COLIN';
            // }
            // else if (_eventCode.includes('spot') || _centerName.includes('sim')) {
            //     //SIM = SPOT 2020/2021.
            //     _FS_Modal_Reg.Group = this.state.signupGradeSelection;
            //     _targetRepo = 'SIM';
            // }
            // else if (_eventCode.includes('ptsmc')) {
            //     //Pusat Tuisyen Sri Mulia Cahaya = Primary Test 2021
            //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;
            //     _targetRepo = 'PTSMC';
            // }
            // else if (_eventCode.includes('oppy') || _centerName.includes('oppy')) {     //2021.09.18
            //     //Little Oppy
            //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;
            //     _targetRepo = 'LITTLEOPPY';
            // }
            // else {
            //     //Other
            //     _FS_Modal_Reg.Group = this.state.signup_StudentGroup;
            //     _targetRepo = 'OTHER';
            // }

            // await this.props.firestore
            //     .collection('LiveQuiz_RegData')
            //     .doc(_targetRepo)
            //     .collection('List')
            //     .doc(userUid)
            //     .set(_FS_Modal_Reg)
            //     .catch(async (error) => {
            //         await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | " + _targetRepo + " | UID: " + this.props.user.uid + " | Error = " + error);
            //     });

            //#endregion old codes.

            //2022.07.29 - save to new collection.
            await this.props.firestore
                .collection('LiveQuiz_NewUser_RegData')     //new collection.
                .doc(userUid)
                .set(_FS_Modal_Reg)
                .catch(async (error) => {
                    await this.props.SetErrorLog(new Date(), "CreateNewUserProfileInFirebase | Save Registration Data | UID: " + String(this.props.user.uid) + " | Error = " + error);
                });

            //=== Save profile modal = again === ends ===//


            //=== Save Participant Info if (isEventRegistration === true) === start ===//
            //2022.11.16
            if (this.props.isEventRegistration === true) {

            }
            //=== Save Participant Info if (isEventRegistration === true) === end ===//


            //Error message if anything goes wrong.
            // if (errorMessage.length > 0) {
            //     this.SetAlert("Invalid Operation", "Something wrong in the progress.<br /><br />" + errorMessage);

            //     //Dangerous = better dont delete any data. Suppose is no need to remove data.
            //     // this.RemoveCreatedData();
            // }

            //If all processes are done & success, goto login page.
            // if (this.state.isRealtimeProfileExists && this.state.isFireStoreProfileExists) {
            // this.SetAlertWithProgressBarLocal("User profile has been created", "Redirecting to Login page...", false);
            this.SetAlertWithProgressBarLocal(Locale("user-profile-created", this.props.Locale),
                Locale("redirect-to-login", this.props.Locale), false);


            //2020.11.26 = check & save login settings for user with first time sign up.
            const saveLogin = localStorage.getItem('saveLoginCredential');
            if (saveLogin === null) {
                //save login settings.
                localStorage.setItem('saveLoginCredential', 'true');
                localStorage.setItem('email', this.state.signupEmail);
                localStorage.setItem('password', this.state.signupPassword);
            }
            // else {
            //     this.props.setEmailPassword(this.state.signupEmail, this.state.signupPassword);
            // }
            // this.props.SetIsFromParentApp(this.state.isFromParentApp);


            // setTimeout(() => {
            //     // this.GotoLoginPage();

            //     //2021.08.19
            //     if (IsFirebaseAccountCreationSuccess === false && IsParentPreExisted) {
            //         this.props.SetAlertConfirm('Notice',
            //             this.GetAutoRegSuccessMessage_ExstingFirebaseUser(),
            //             this.props.Feedback.Confirm, false, false, true, false, false);
            //     }
            //     else if (IsFirebaseAccountCreationSuccess && IsCmsAccountCreationSuccess) {
            //         // this.GotoLoginPage();
            //         this.props.SetAlertConfirm('Notice',
            //             this.GetAutoRegSuccessMessage(),
            //             this.props.Feedback.Confirm, false, false, true, false, false);
            //     }
            //     else if (IsFirebaseAccountCreationSuccess && IsCmsAccountCreationSuccess === false) {
            //         // this.GotoLoginPage();
            //         this.props.SetAlertConfirm('Notice',
            //             this.GetAutoRegSuccessMessage_NewFirebaseUser_ExstingParentUser(),
            //             this.props.Feedback.Confirm, false, false, true, false, false);
            //     }
            //     else if (IsFirebaseAccountCreationSuccess === false && IsCmsAccountCreationSuccess === false) {
            //         this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //             Locale("unable-to-create-user", this.props.Locale) + "<br /><br />Error Code: 10006", false);
            //     }
            //     else {
            //         //both FB & CMS - creation failed.
            //         this.SetAlertWithProgressBarLocal(Locale("invalid-signup", this.props.Locale),
            //             Locale("unable-to-create-user", this.props.Locale) + "<br /><br />Error Code: 10005", false);
            //     }

            // }, 1000);
            // }
            return true;
        }
        return false;
    }

    //Dangerous = better dont delete any data. Suppose is no need to remove data.
    // RemoveCreatedData = async () => {
    //     let errorMessage = "";

    //     //Delete Profile in Realtime Databse.
    //     if (this.state.isRealtimeProfileExists) {
    //         this.props.dbCommon.ref("Common/" + this.props.user.uid).remove()
    //             .then(() => {

    //             })
    //             .catch(error => {
    //                 errorMessage += "Firebase remove failed : " + error.code + "<br />" + error.message + "<br /><br />";
    //             })
    //     }

    //     //Delete Profile in FireStore.
    //     if (this.state.isFireStoreProfileExists) {
    //         this.props.firestore.collection("User")
    //             .doc(this.props.user.uid).delete()
    //             .then(() => {

    //             })
    //             .catch(error => {
    //                 errorMessage += "FireStore remove failed : " + error.code + "<br />" + error.message + "<br /><br />";
    //             })
    //     }

    //     //Delete User Account.
    //     this.props.auth.currentUser.delete()
    //         .then(() => {

    //         })
    //         .catch(error => {
    //             errorMessage += "User remove failed : " + error.code + "<br />" + error.message + "<br /><br />";
    //         });

    //     //Error message if anything goes wrong.
    //     if (errorMessage.length > 0) {
    //         setTimeout(() => {
    //             this.SetAlert("Invalid Operation", "Something wrong in the progress.<br /><br />" + errorMessage);
    //         }, 1500);
    //     }
    // }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    GotoLoginPage = () => {
        this.props.resetUserLogin(this.state.signupEmail, this.state.signupPassword);
        let _loc = '/';
        if (this.props.isFromParentApp) {
            // let _organizer = this.props.OrganizerIdentity !== '' ? this.props.OrganizerIdentity + '/' : '';
            _loc = '/?from=parent&to=history';
        }
        this.setState({
            redirectLink: _loc,
            redirect: true,
        });
    }

    GetStandard = (idx) => {
        let text = "";
        if (this.props.Locale === Lang.Chinese)
            text = idx + " " + Locale("standard", this.props.Locale);
        else
            text = Locale("standard", this.props.Locale) + " " + idx;
        return text;
    }

    // GetForm = (idx) => {
    //     let text = "";
    //     if (this.props.Locale === Lang.Chinese) {
    //         if (idx > 0 && idx < 4)
    //             text = Locale("form-123", this.props.Locale) + " " + idx + " (" + Locale("form", this.props.Locale) + idx + ")";
    //         else if (idx > 3 && idx < 6)
    //             text = Locale("form-45", this.props.Locale) + " " + (idx - 3) + " (" + Locale("form", this.props.Locale) + idx + ")";
    //         else if (idx === 6)
    //             text = Locale("form-6", this.props.Locale) + " (" + Locale("form", this.props.Locale) + idx + ")";
    //     }
    //     else {
    //         text = Locale("form", this.props.Locale) + " " + idx;
    //     }
    //     return text;
    // }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        // else if (this.props.user !== null) {
        //     return <Redirect to='/home' />;
        // }
        else {
            return (
                <>
                    {/* <div
                        style={{
                            position: "absolute",
                            overflow: "hidden",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            top: 0,
                            justifyContent: "center"
                        }}
                    >
                        <img src={Background} alt="Live Quiz" width={window.innerWidth} height={window.innerHeight} />
                    </div> */}

                    <div style={{
                        // backgroundColor: 'violet',
                        backgroundColor: 'transparent',
                        height: 'auto',
                        width: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 0,
                        paddingTop: 25,
                    }}>
                        <div className="container" style={{ maxWidth: '550px' }}>
                            <div className="row">
                                <aside className="col-sm-12 font-weight-bold">
                                    {/* <p style={{
                                        color: 'lavender', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                    }}>iKEY Kidz (WEB)</p> */}

                                    <div className="card" style={{ backgroundColor: '#007bff' }}>
                                        <article className="card-body text-center">
                                            <span style={{
                                                color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                float: "left"
                                            }}>iKEY Live Quiz</span>

                                            {/* <h4 className="card-title mb-4 mt-1"><b>{Locale("signup-register", this.props.Locale)}</b></h4> */}

                                            <LocalizationSwitcher
                                                Locale={this.props.Locale}
                                                SetLocaleSetting={this.props.SetLocaleSetting}
                                            />
                                        </article>
                                    </div>

                                    {/* <p><Button variant="secondary" onClick={() =>
                                        this.TestSendEmail('my name', 'ikeyedutech.unity@gmail.com', '1234567890')
                                    }>Test Email</Button></p> */}

                                    <div className="card">
                                        <article className="card-body">
                                            <a href="/" className="float-right btn btn-outline-primary">back</a>
                                            <h4 className="card-title mb-2 mt-1"><b>{Locale("signup-register", this.props.Locale)}</b></h4>

                                            {/* <form> */}

                                            <div className="form-group font-weight-normal">
                                                <ul style={{ paddingLeft: 10 }}>
                                                    <li>
                                                        <div className='row'>
                                                            <div className='col-1 text-right'>•</div>
                                                            <div className='col-11'>
                                                                <span style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: 'bold',
                                                                    color: 'red',
                                                                }}>{Locale("skip-this-if-registered-in-app", this.props.Locale)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='row'>
                                                            <div className='col-1 text-right'>•</div>
                                                            <div className='col-11'>
                                                                <span style={{
                                                                    fontSize: '12px', color: 'gray'
                                                                }}>{Locale("fill-up-required-fields", this.props.Locale)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <hr />

                                            {/* 2021.03.19 - add logic to control Ui - Login or New Registeration */}
                                            <div className="form-group" style={{ marginBottom: -7 }} >
                                                <div className="row" style={{ paddingLeft: 12 }}>
                                                    <div className="col">
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" name="loginOrRegister"
                                                                    onChange={() => this.Select_LoginOrRegister(true)}
                                                                    disabled={this.state.isLoading}
                                                                />&nbsp;&nbsp;{Locale("label-existing-user", this.props.Locale)}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingLeft: 12, paddingTop: 10 }}>
                                                    <div className="col">
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" name="loginOrRegister"
                                                                    onChange={() => this.Select_LoginOrRegister(false)}
                                                                    disabled={this.state.isLoading}
                                                                />&nbsp;&nbsp;{Locale("label-new-user", this.props.Locale)}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ paddingBottom: 7 }} />
                                            {
                                                this.state.isLoading === null ? null
                                                    :
                                                    this.state.toggleUi_LoginOrRegister ?
                                                        this.state.isExistingUser ?
                                                            <>
                                                                {/* Existing User Login Ui - same design in Login Page */}
                                                                <div className="card">
                                                                    <article className="card-body">
                                                                        {/* <button
                                                                        type="button"
                                                                        className="float-right btn btn-outline-primary"
                                                                        onClick={this.GotoSignUp}
                                                                    >{Locale("signup-register", this.props.Locale)}</button> */}
                                                                        <h4 className="card-title mb-4 mt-1"><b>{Locale("signin-login", this.props.Locale)}</b></h4>
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label>{Locale("your-email", this.props.Locale)}</label>
                                                                                <input name="username"
                                                                                    className="form-control"
                                                                                    placeholder={Locale("email-address", this.props.Locale)}
                                                                                    type="email"
                                                                                    autoComplete='username'
                                                                                    onChange={this.handleSetLoginEmail} value={this.state.loginEmail}
                                                                                    onFocus={() => this.setState({ toggleLoginNotice: true })}
                                                                                    onBlur={() => this.setState({ toggleLoginNotice: false })}
                                                                                />
                                                                                {
                                                                                    // this.state.toggleLoginNotice ?
                                                                                    //     <span
                                                                                    //         style={{ padding: 10, color: "gray" }}
                                                                                    //     >* {Locale("shared-login-notice", this.props.Locale)}</span>
                                                                                    //     : null
                                                                                }
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <a className="float-right" href="/passwordReset">{Locale("forgot-password", this.props.Locale)}</a>
                                                                                <label>{Locale("your-password", this.props.Locale)}</label>
                                                                                <input className="form-control"
                                                                                    // placeholder={"******"}
                                                                                    placeholder={CheckNullValue(this.state.loginPassword) !== null ? '******' : ''}   //2020.11.28
                                                                                    type="password"
                                                                                    autoComplete='current-password'
                                                                                    onChange={this.handleSetLoginPassword}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <div className="row">
                                                                                    <div className="col-7">
                                                                                        <div className="checkbox">
                                                                                            <label> <input type="checkbox"
                                                                                                onChange={this.handleSetSavePasword} checked={this.state.saveLoginCredential}
                                                                                            />&nbsp;{Locale("keep-email-password", this.props.Locale)}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-5">
                                                                                        <LocalizationSwitcher
                                                                                            Locale={this.props.Locale}
                                                                                            SetLocaleSetting={this.props.SetLocaleSetting}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <button type="button" className="btn btn-primary btn-block"
                                                                                    onClick={this.handleLogin}
                                                                                >{Locale("signin-login", this.props.Locale)}</button>
                                                                            </div>
                                                                        </form>
                                                                    </article>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {/* New User Registration Ui */}

                                                                <div className="form-group">
                                                                    <label>{Locale("your-email", this.props.Locale)} *</label>
                                                                    <input name="Email" className="form-control" placeholder={Locale("email-address", this.props.Locale)} type="email"
                                                                        onChange={this.handleSetEmail} value={this.state.signupEmail}
                                                                    />
                                                                </div>

                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-1"></div>
                                                                        <div className="col-11">
                                                                            <label>{Locale("your-email", this.props.Locale)} ({Locale("confirm", this.props.Locale)}) *</label>
                                                                            <input name="Email-Confirm" className="form-control" placeholder={Locale("email-address", this.props.Locale) + " (" + Locale("confirm", this.props.Locale) + ")"} type="email"
                                                                                onChange={this.handleSetEmailConfirm} value={this.state.signupEmail_Confirm}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <label>{Locale("your-password", this.props.Locale)} *
                                                                        <span style={{ fontSize: 10, color: 'gray' }}>&nbsp;({Locale("password-min-req", this.props.Locale)})</span>
                                                                    </label>
                                                                    <input className="form-control" placeholder="********" type="text"
                                                                        onChange={this.handleSetPassword} value={this.state.signupPassword}
                                                                    />
                                                                </div>

                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-1"></div>
                                                                        <div className="col-11">
                                                                            <label>{Locale("your-password", this.props.Locale)} ({Locale("confirm", this.props.Locale)}) *
                                                                                <span style={{ fontSize: 10, color: 'gray' }}>&nbsp;({Locale("password-min-req", this.props.Locale)})</span>
                                                                            </label>
                                                                            <input className="form-control" placeholder="********" type="text"
                                                                                onChange={this.handleSetPasswordConfirm} value={this.state.signupPassword_Confirm}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <label>{Locale("your-name", this.props.Locale)} *</label>
                                                                    <input name="FullName" className="form-control" placeholder={Locale("full-name", this.props.Locale)} type="text"
                                                                        onChange={this.handleSetName} value={this.state.signupStudentName}
                                                                    />
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    {/* <label>{Locale("contact-number", this.props.Locale)} *</label>
                                                                <input name="ContactNumber" className="form-control" placeholder={Locale("contact-number-sample", this.props.Locale)} type="text"
                                                                    onChange={this.handleSetContactNumber} value={this.state.signupContactNumber}
                                                                /> */}
                                                                    <table cellPadding='0' cellSpacing='0' border='0' width='100%'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <label>{Locale("contact-number", this.props.Locale)} *</label>
                                                                                </td>
                                                                                <td align='right'>
                                                                                    <span style={{ fontSize: 14, color: 'gray', }} hidden={CheckNullValue(this.state.signupContactNumber) === null}>(Preview: {this.state.signupContactNumber})</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <DropdownButton
                                                                                        id="signup-dialing-code-dropdown-button"
                                                                                        title={this.state.signup_DialingCode}
                                                                                        drop="down"
                                                                                        onSelect={this.handleDialingCodeSelect}
                                                                                    >
                                                                                        <Dropdown.Item as="button" eventKey={'+60'} value='+60'>{Locale("label-country-my", this.props.Locale)}</Dropdown.Item>
                                                                                        <Dropdown.Item as="button" eventKey={'+65'} value='+65'>{Locale("label-country-sg", this.props.Locale)}</Dropdown.Item>
                                                                                        <Dropdown.Item as="button" eventKey={'+86'} value='+86'>{Locale("label-country-cn", this.props.Locale)}</Dropdown.Item>
                                                                                        <Dropdown.Item as="button" eventKey={'+886'} value='+886'>{Locale("label-country-tw", this.props.Locale)}</Dropdown.Item>
                                                                                        <Dropdown.Item as="button" eventKey={'+81'} value='+81'>{Locale("label-country-jp", this.props.Locale)}</Dropdown.Item>
                                                                                    </DropdownButton>
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        ref={this.phoneNumberRef}
                                                                                        name="PhoneNumber"
                                                                                        type="text"
                                                                                        inputMode="numeric"
                                                                                        pattern="[0-9]*"
                                                                                        className="form-control"
                                                                                        placeholder={Locale("phone-number-sample", this.props.Locale)}
                                                                                        onChange={event => this.setPhoneNumber(event.target.value)}
                                                                                    />
                                                                                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.debugText }} /> */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr hidden={CheckNullValue(this.state.signup_PhoneNumber) === null}>
                                                                                <td>&nbsp;</td>
                                                                                <td align='center'>
                                                                                    <div style={{ width: '100%' }}>
                                                                                        <span style={{ fontSize: 14, color: 'lightgray', textAlign: 'right', }} >{
                                                                                            '(' + this.state.signup_PhoneNumber.length + '/' + (15 - this.state.signup_DialingCode.length) + ')'
                                                                                        }</span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <label>{Locale("label-gender", this.props.Locale)}</label>
                                                                    <Select
                                                                        options={this.state.GenderOptions}
                                                                        // openMenuOnFocus={true}
                                                                        placeholder={
                                                                            CheckNullValue(this.state.signup_Gender) !== null ?
                                                                                // this.state.signup_Gender
                                                                                this.GetOptionsLabel(this.state.GenderOptions, this.state.signup_Gender)
                                                                                : Locale("not-specify-gender", this.props.Locale)
                                                                        }
                                                                        // placeholder={Locale("not-specify-gender", this.props.Locale)}
                                                                        value={this.state.signup_Gender}
                                                                        onChange={(option) => this.handleSetGender(option)}
                                                                    />
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <label>{Locale("label-race", this.props.Locale)}</label>
                                                                    <Select
                                                                        options={this.state.RaceOptions}
                                                                        // openMenuOnFocus={true}
                                                                        placeholder={
                                                                            CheckNullValue(this.state.signup_Race) !== null ?
                                                                                // this.state.signup_Race
                                                                                this.GetOptionsLabel(this.state.RaceOptions, this.state.signup_Race)
                                                                                : Locale("not-specify-race", this.props.Locale)
                                                                        }
                                                                        // placeholder={Locale("not-specify-race", this.props.Locale)}
                                                                        value={this.state.signup_Race}
                                                                        onChange={(option) => this.handleSetRace(option)}
                                                                    />
                                                                </div>

                                                                <hr />

                                                                {/* <div className="form-group">
                                                                <label>{Locale("label-religion", this.props.Locale)} *</label>
                                                                <Select
                                                                    options={[
                                                                        { value: Locale("label-religion-1", this.props.Locale), label: Locale("label-religion-1", this.props.Locale) },
                                                                        { value: Locale("label-religion-2", this.props.Locale), label: Locale("label-religion-2", this.props.Locale) },
                                                                        { value: Locale("label-religion-3", this.props.Locale), label: Locale("label-religion-3", this.props.Locale) },
                                                                        { value: Locale("label-religion-4", this.props.Locale), label: Locale("label-religion-4", this.props.Locale) },
                                                                        { value: Locale("label-religion-5", this.props.Locale), label: Locale("label-religion-5", this.props.Locale) },
                                                                        { value: Locale("label-religion-0", this.props.Locale), label: Locale("label-religion-0", this.props.Locale) },
                                                                    ]}
                                                                    placeholder={
                                                                        this.state.signup_Religion.length > 0 ?
                                                                            this.state.signup_Religion
                                                                            : Locale("not-specify-religion", this.props.Locale)
                                                                    }
                                                                    value={this.state.signup_Religion}
                                                                    onChange={(option) => this.handleSetReligion(option)}
                                                                />
                                                            </div>

                                                            <hr /> */}

                                                                {/* <div className="form-group">
                                                <div className="row">
                                                    <aside className="col-sm-12">
                                                        <label>{Locale("label-state", this.props.Locale)}</label>
                                                    </aside>
                                                </div>

                                                <div className="row">
                                                    <aside className="col-sm-3">
                                                        <DropdownButton
                                                            id="signup-national-state-dropdown-button"
                                                            // title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Grade&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                                            title={Locale("label-national-state", this.props.Locale)}
                                                            drop="up"
                                                            onSelect={this.handleNationalStateSelect}
                                                        >
                                                            {
                                                                NationalState.map((data, key) => (
                                                                    < Dropdown.Item as="button" eventKey={data}>{data}</Dropdown.Item>
                                                                ))
                                                            }
                                                        </DropdownButton>
                                                    </aside>
                                                    <aside className="col-sm-9" style={{ alignSelf: 'center' }}>
                                                        <input name="" className="form-control" placeholder={this.state.signup_NationalState} type="text" disabled />
                                                    </aside>
                                                </div>
                                            </div> */}

                                                                <div className="form-group">
                                                                    <label>{Locale("label-state", this.props.Locale)} *</label>
                                                                    <Select
                                                                        options={this.state.nationalStateListArray}
                                                                        // openMenuOnFocus={true}
                                                                        // menuIsOpen={this.state.showSelectNationalStateOption}
                                                                        placeholder={
                                                                            CheckNullValue(this.state.signup_NationalState) !== null ?
                                                                                this.state.signup_NationalState
                                                                                : Locale("label-national-state", this.props.Locale)
                                                                        }
                                                                        value={this.state.signup_NationalState}
                                                                        onInputChange={(e) => {
                                                                            let inputText = CheckStringEmpty(e);
                                                                            this.setState({
                                                                                showSelectNationalStateOption: inputText.length >= 1 ? true : false,
                                                                            });
                                                                        }}
                                                                        // onChange={(val) => this.handleSetProfile(Profile.NationalState, val)}
                                                                        onChange={(option) => this.handleNationalStateSelect(option)}
                                                                    />
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <label>{Locale("label-district", this.props.Locale)} *</label>
                                                                    <Select
                                                                        options={this.state.filteredByState_DistrictAreaList}
                                                                        // openMenuOnFocus={true}
                                                                        // menuIsOpen={this.state.showSelectDistrictAreaOption}
                                                                        placeholder={
                                                                            CheckNullValue(this.state.signup_DistrictArea) !== null ?
                                                                                this.state.signup_DistrictArea
                                                                                : Locale("label-district-area", this.props.Locale)
                                                                        }
                                                                        value={this.state.signup_DistrictArea}
                                                                        onInputChange={(e) => {
                                                                            let inputText = CheckStringEmpty(e);
                                                                            this.setState({
                                                                                showSelectDistrictAreaOption: inputText.length >= 1 ? true : false,
                                                                            });
                                                                        }}
                                                                        // onChange={(val) => this.handleSetProfile(Profile.NationalState, val)}
                                                                        onChange={(option) => this.handleDistrictAreaSelect(option)}
                                                                        isDisabled={this.state.signup_NationalState === ''}
                                                                    />
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <label>{Locale("label-school", this.props.Locale)}</label>
                                                                    {/* <input name="SchoolName" className="form-control" placeholder={Locale("text-school-name", this.props.Locale)} type="text"
                                                                    onChange={this.handleSetSchoolName} value={this.state.signup_SchoolName}
                                                                /> */}
                                                                    {/* <AutoComplete_v1
                                                                    options={SchoolList}
                                                                /> */}
                                                                    <Select
                                                                        id='r-select-school-register'
                                                                        classNamePrefix={'r-select'}
                                                                        options={this.state.schoolListArray}
                                                                        menuIsOpen={this.state.showSelectSchoolListOption}
                                                                        // openMenuOnFocus={true}
                                                                        placeholder={
                                                                            CheckNullValue(this.state.signup_SchoolName) !== null ?
                                                                                this.state.signup_SchoolName
                                                                                : Locale("text-school-name", this.props.Locale)
                                                                        }
                                                                        value={this.state.signup_SchoolName}
                                                                        onInputChange={(e) => {
                                                                            let inputText = CheckStringEmpty(e);
                                                                            this.setState({
                                                                                showSelectSchoolListOption: inputText.length >= 2 ? true : false,
                                                                            });
                                                                        }}
                                                                        onChange={this.handleSetSchoolName}
                                                                    />
                                                                    {/* <span style={{
                                                                    fontSize: '14px', color: 'gray', fontWeight: 'normal',
                                                                    // paddingLeft: this.props.Locale === Lang.Chinese ? 12 : 12,
                                                                    paddingLeft: 12
                                                                }}>{ }</span> */}
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px', color: 'gray', fontWeight: 'normal',
                                                                            // paddingLeft: this.props.Locale === Lang.Chinese ? 12 : 12,
                                                                            paddingLeft: 12, paddingTop: 5
                                                                        }}
                                                                        dangerouslySetInnerHTML={{ __html: Locale("text-school-sample", this.props.Locale) }}>
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                {
                                                                    // <div className="form-group">
                                                                    //     <div className="row">
                                                                    //         <aside className="col-sm-12">
                                                                    //             <label>{Locale("your-grade", this.props.Locale)} *</label>
                                                                    //         </aside>
                                                                    //     </div>

                                                                    //     <div className="row">
                                                                    //         <aside className="col-sm-4">
                                                                    //             <DropdownButton
                                                                    //                 id="signup-dropdown-button"
                                                                    //                 // title="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Grade&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                                                    //                 title={Locale("grade", this.props.Locale)}
                                                                    //                 drop="up"
                                                                    //                 onSelect={this.handleGradeSelect}
                                                                    //             >
                                                                    //                 <Dropdown.Item as="button" eventKey={'Pre-School'}>{Locale("pre-school", this.props.Locale)}</Dropdown.Item>
                                                                    //                 {
                                                                    //                     [1, 2, 3, 4, 5, 6].map((stdIdx) => (
                                                                    //                         < Dropdown.Item as="button" eventKey={'Standard ' + stdIdx}>{this.GetStandard(stdIdx)}</Dropdown.Item>
                                                                    //                     ))
                                                                    //                 }
                                                                    //                 {
                                                                    //                     [1, 2, 3, 4, 5, 6].map((stdIdx) => (
                                                                    //                         <Dropdown.Item as="button" eventKey={'Form ' + stdIdx}>{this.GetForm(stdIdx)}</Dropdown.Item>
                                                                    //                     ))
                                                                    //                 }
                                                                    //                 <Dropdown.Item as="button" eventKey={'Other'}>{Locale("other", this.props.Locale)}</Dropdown.Item>
                                                                    //             </DropdownButton>
                                                                    //         </aside>
                                                                    //         <aside className="col-sm-8" style={{ alignSelf: 'center' }}>
                                                                    //             {/* <label>{this.state.signupGradeSelection}</label> */}
                                                                    //             <input name="" className="form-control" placeholder={this.state.signupGradeSelection} type="text" disabled />
                                                                    //         </aside>
                                                                    //     </div>
                                                                    // </div>

                                                                    // <hr />
                                                                }

                                                                <div className="form-group">
                                                                    <label>{Locale("your-grade", this.props.Locale)} *</label>
                                                                    <Select
                                                                        options={this.state.gradeOptions}
                                                                        // openMenuOnFocus={true}
                                                                        placeholder={
                                                                            CheckNullValue(this.state.signupGradeSelection) !== null ?
                                                                                this.getGradeOptionText(this.state.signupGradeSelection)
                                                                                :
                                                                                Locale("grade", this.props.Locale)
                                                                        }
                                                                        value={this.state.signupGradeSelection}
                                                                        onChange={(option) => this.handleGradeSelect(option)}
                                                                    />
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <label>{Locale("your-classroom", this.props.Locale)} </label>
                                                                    <input name="FullName" className="form-control" placeholder={Locale("classroom-name", this.props.Locale)} type="text"
                                                                        onChange={this.handleSetClassroom} value={this.state.signup_Classroom}
                                                                    />
                                                                </div>

                                                                <hr />

                                                                {
                                                                    // CheckBoolean(this.props.isEventRegistration) && String(this.props.eventName) === 'spot2020' ?
                                                                    CheckBoolean(this.props.isEventRegistration) ?
                                                                        <>
                                                                            <div className="form-group">
                                                                                <label>
                                                                                    {Locale("label-center-student-1", this.props.Locale)}
                                                                                    {/* {this.props.centerName} */}
                                                                                    {CheckNullValue(this.props.centerDisplayName) !== null ? this.props.centerDisplayName : this.props.centerName}
                                                                                    {Locale("label-center-student-2", this.props.Locale)} *
                                                                                </label>
                                                                                <Select
                                                                                    options={[
                                                                                        { value: 'Not Specify', label: this.getOptionText('Not Specify') },
                                                                                        { value: 'Yes', label: this.getOptionText('Yes') },
                                                                                        { value: 'No', label: this.getOptionText('No') },
                                                                                    ]}
                                                                                    // openMenuOnFocus={true}
                                                                                    value={this.state.signup_CenterStudent}
                                                                                    placeholder={this.getOptionText(this.state.signup_CenterStudent)}
                                                                                    onChange={(option) => this.handleSetIsCenterStudent(option)}
                                                                                />
                                                                            </div>

                                                                            <hr />
                                                                        </>
                                                                        : null
                                                                }

                                                                {
                                                                    //2020.12.14    //Student Group
                                                                    // CheckBoolean(this.props.isEventRegistration) && this.props.eventName === 'Abacus Challenge' ?
                                                                    //2021.01.14    //event checking in options & placeholder section.
                                                                    CheckBoolean(this.props.isEventRegistration) ?
                                                                        <>
                                                                            <div className="form-group">
                                                                                <label>
                                                                                    {Locale("label-select-student-group", this.props.Locale)}
                                                                                    {this.props.eventName} *
                                                                                </label>
                                                                                <Select
                                                                                    // options={[
                                                                                    //     { value: 'Not Specify', label: this.getGroupAbacusText('Not Specify') },
                                                                                    //     { value: 'Group A', label: this.getGroupAbacusText('Group A') },
                                                                                    //     { value: 'Group B', label: this.getGroupAbacusText('Group B') },
                                                                                    //     { value: 'Group C', label: this.getGroupAbacusText('Group C') },
                                                                                    //     { value: 'Group D', label: this.getGroupAbacusText('Group D') },
                                                                                    // ]}

                                                                                    //2021.01.14
                                                                                    options={this.getGroupOptions()}
                                                                                    value={this.state.signup_StudentGroup}

                                                                                    // placeholder={this.getGroupAbacusText(this.state.signup_StudentGroup)}
                                                                                    placeholder={this.getPlaceholder_GroupText()}   //2021.01.14                                                                

                                                                                    onChange={(option) => this.handleSetStudentGroup(option)}
                                                                                />
                                                                            </div>

                                                                            <hr />
                                                                        </>
                                                                        : null
                                                                }

                                                                <div className="form-group">
                                                                    <div className='row'>
                                                                        <div className='col'>
                                                                            <label>{Locale("other", this.props.Locale)}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row' style={{ padding: 5, paddingLeft: 15 }}>
                                                                        <div className='col-1'>
                                                                            <input name="12AndBelow" type="checkbox"
                                                                                style={{ width: 20, height: 20 }}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        toggleShowHide_GuardianNameField: !this.state.toggleShowHide_GuardianNameField,
                                                                                        signup_GuardianName: '',
                                                                                    })
                                                                                }}
                                                                                value={this.state.toggleShowHide_GuardianNameField}
                                                                                checked={this.state.toggleShowHide_GuardianNameField}
                                                                                disabled={this.state.disable_GuardianNameField}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                        <div className='col-11'>
                                                                            {Locale("label-12-and-below", this.props.Locale)}
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.toggleShowHide_GuardianNameField ?
                                                                            <div className='row'>
                                                                                <div className='col'>

                                                                                    <div className="form-group" style={{ paddingLeft: 57 }}>
                                                                                        <hr />
                                                                                        <label>{Locale("label-guardian-name", this.props.Locale)} *</label>
                                                                                        <input name="GuardianName" className="form-control" placeholder={Locale("label-guardian-name", this.props.Locale)} type="text"
                                                                                            onChange={this.handleSetGuardianName} value={this.state.signup_GuardianName}
                                                                                        />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">
                                                                    <div className='row'>
                                                                        <div className='col'>
                                                                            <label>{Locale("label-agree-policy-tnc", this.props.Locale)} *</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row' style={{ padding: 5, paddingLeft: 15 }}>
                                                                        <div className='col-1'>
                                                                            <input name="PolicyTnc" type="checkbox"
                                                                                style={{ width: 20, height: 20 }}
                                                                                onClick={this.handleSetPolicyTnc}
                                                                                value={this.state.signup_AgreePolicyTnc}
                                                                                checked={this.state.signup_AgreePolicyTnc}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                        <div className='col-11'>
                                                                            {Locale("text-accept", this.props.Locale)}
                                                                            <button
                                                                                style={{
                                                                                    background: 'none',
                                                                                    padding: 0,
                                                                                    color: 'blue',
                                                                                    fontWeight: 'bold',
                                                                                    border: 'none',
                                                                                    textDecoration: 'underline',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => window.open('https://www.ikeyedutech.com.my/privacy-policy', '_new')}
                                                                            >{Locale("text-privacy-policy", this.props.Locale)}</button>
                                                                            {Locale("and", this.props.Locale)}
                                                                            <button
                                                                                style={{
                                                                                    background: 'none',
                                                                                    padding: 0,
                                                                                    color: 'blue',
                                                                                    fontWeight: 'bold',
                                                                                    border: 'none',
                                                                                    textDecoration: 'underline',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => window.open('https://www.ikeyedutech.com.my/terms', '_new')}
                                                                            >{Locale("text-tnc", this.props.Locale)}</button>
                                                                            {Locale("text-accept-1", this.props.Locale)}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                <div className="form-group">&nbsp;</div>

                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <aside className="col-sm-6">
                                                                            <button type="button" className="btn btn-default btn-block border-dark"
                                                                                onClick={this.handleResetForm}
                                                                            >{Locale("reset", this.props.Locale)}</button>
                                                                        </aside>
                                                                        <aside className="col-sm-6">
                                                                            <button type="submit" className="btn btn-primary btn-block"
                                                                                onClick={this.handleSubmitForm}
                                                                                disabled={this.state.submitInProgress}
                                                                            >{Locale("signup-register", this.props.Locale)}</button>
                                                                        </aside>
                                                                    </div>
                                                                </div>

                                                                {/* </form> */}
                                                            </>
                                                        : null
                                            }
                                        </article>
                                    </div>
                                </aside>
                            </div>

                            <div style={{ height: '200px', width: '100%' }}>
                                <span>&nbsp;</span>
                            </div>
                        </div>

                        <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>{Locale("invalid-info", this.props.Locale)}</Modal.Title>
                            </Modal.Header>
                            {/* <Modal.Body><div dangerouslySetInnerHTML={this.getMessage()}></div></Modal.Body> */}
                            <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></div></Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleCloseModal}>{Locale("close", this.props.Locale)}</Button>
                                {/* <Button variant="primary" onClick={this.handleCloseModal}>
                            Save Changes </Button> */}
                            </Modal.Footer>
                        </Modal>

                    </div >
                </>
            );
        }
    }
}